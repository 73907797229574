import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Checkbox, FormControlLabel, Button, TextField } from '@mui/material';
import '../styles/ChargeRecordModal.css';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ChargeRecordModal = ({ open, handleClose, inventoryItems, serviceItems, miscellaneousItems, onChargeRecordSelect, type }) => {
    const [selectedInventoryIds, setSelectedInventoryIds] = useState([]);
    const [selectedServiceIds, setSelectedServiceIds] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [selectedMiscellaneousIds, setSelectedMiscellaneousIds] = useState([]);

    const handleSelect = () => {
        onChargeRecordSelect(selectedInventoryIds, selectedServiceIds, selectedMiscellaneousIds, type);
        handleClose();
    };

    const handleSelectInventoryItem = (id) => {
        const currentIndex = selectedInventoryIds.indexOf(id);
        const newChecked = [...selectedInventoryIds];

        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedInventoryIds(newChecked);
    };

    const handleSelectServiceItem = (id) => {
        const currentIndex = selectedServiceIds.indexOf(id);
        const newChecked = [...selectedServiceIds];

        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedServiceIds(newChecked);
    };

    const handleSelectMiscellaneousItem = (id) => {
        setSelectedMiscellaneousIds(prev => [...prev, id]);
    };

    const handleSearchChange = (event) => {
        setSearchString(event.target.value);
    };

    const filteredInventoryItems = inventoryItems.filter(item =>
        item.name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.description.toLowerCase().includes(searchString.toLowerCase())
    );

    const filteredServiceItems = serviceItems.filter(item =>
        item.name.toLowerCase().includes(searchString.toLowerCase())
    );

    const filteredMiscellaneousItems = miscellaneousItems.filter(item =>
        item.name.toLowerCase().includes(searchString.toLowerCase())
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="charge-record-modal-title"
            aria-describedby="charge-record-modal-description"
        >
            <Box sx={modalStyle}>
                <h3 id="charge-record-modal-title">Select {type === 'onTurnkey' ? 'On Turnkey' : 'Off Turnkey'} Charges</h3>
                <TextField
                    fullWidth
                    label="Search"
                    value={searchString}
                    onChange={handleSearchChange}
                    margin="normal"
                />
                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <h4>Inventory Items</h4>
                    {filteredInventoryItems.map((item) => (
                        <FormControlLabel
                            key={item.id}
                            control={
                                <Checkbox 
                                    checked={selectedInventoryIds.includes(item.id)} 
                                    onChange={() => handleSelectInventoryItem(item.id)} 
                                />
                            }
                            label={`${item.name} - ${item.description}`}
                        />
                    ))}
                    <h4>Service Items</h4>
                    {filteredServiceItems.map((item) => (
                        <FormControlLabel
                            key={item.id}
                            control={
                                <Checkbox 
                                    checked={selectedServiceIds.includes(item.id)} 
                                    onChange={() => handleSelectServiceItem(item.id)} 
                                />
                            }
                            label={item.name}
                        />
                    ))}
                    {/* Miscellaneous Items */}
                    <h4>Miscellaneous Items</h4>
                    {filteredMiscellaneousItems.map((item) => (
                        <FormControlLabel
                            key={item.id}
                            control={<Checkbox onChange={() => handleSelectMiscellaneousItem(item.id)} />}
                            label={`${item.name}`}
                        />
                    ))}

                </div>
                <Button className='btn hover' onClick={handleSelect} variant="contained" color="primary">
                    Add Selected
                </Button>
            </Box>
        </Modal>
    );
};

ChargeRecordModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    inventoryItems: PropTypes.array.isRequired,
    serviceItems: PropTypes.array.isRequired,
    miscellaneousItems: PropTypes.array.isRequired,
    onChargeRecordSelect: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['onTurnkey', 'offTurnkey']).isRequired,
};

export default ChargeRecordModal;
