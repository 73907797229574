export function convertTo24Hour(hour, minute, meridian) {
  let hourValue = parseInt(hour, 10);
  const minuteValue = minute === '00' ? '00' : parseInt(minute, 10).toString().padStart(2, '0');

  if (meridian === 'PM' && hourValue !== 12) {
    hourValue += 12;
  } else if (meridian === 'AM' && hourValue === 12) {
    hourValue = 0;
  }

  const formattedHour = hourValue.toString().padStart(2, '0');
  const formattedMinute = minuteValue;

  return `${formattedHour}:${formattedMinute}`;
}
