import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import logo from '../assets/JMR-Logo.png';
import { PDFDocument } from 'pdf-lib';

// Get App URL
const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
  throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const sendPdfToBackend = async (pdfBlob, token) => {
  try {
    const formData = new FormData();
    formData.append('pdf', pdfBlob, 'dwr.pdf');

    const response = await axios.post('/api/send-dwr-email/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log('PDF sent to backend successfully:', response.data);
  } catch (error) {
    console.error('Error sending PDF to backend:', error);
  }
};

const fetchMiscellaneousItemDetailsById = async (id, token) => {
  try {
    const response = await axios.get(`${appUrl}/jmrapp/miscellaneous-item/${id}/`, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch miscellaneous item details:', error);
    return null;
  }
};

const fetchServiceItemDetailsById = async (id, token) => {
  try {
    const response = await axios.get(`${appUrl}/jmrapp/serviceitems/${id}`, {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch service item details:', error);
    return null;
  }
};

const fetchInventoryItemDetailsById = async (id, token, wellId) => {
  try {
    // Fetch the inventory item details
    const itemResponse = await axios.get(`${appUrl}/jmrapp/inventoryitems/${id}/`, {
      headers: { 'Authorization': `Bearer ${token}` },
    });

    // Fetch the well details to get its inventory pricebooks
    const wellResponse = await axios.get(`${appUrl}/jmrapp/wells/${wellId}/`, {
      headers: { 'Authorization': `Bearer ${token}` },
    });

    const inventoryItem = itemResponse.data;
    const wellPricebooks = wellResponse.data.inventory_pricebook;

    // Find the matching pricebook entry for this item
    const matchingPricebook = wellPricebooks.find(pb => pb.inventory_item === inventoryItem.id);

    // Use the pricebook price if available, otherwise use the base price
    const price = matchingPricebook ? matchingPricebook.price : inventoryItem.price;

    return {
      ...inventoryItem,
      price: price,
    };
  } catch (error) {
    console.error('Failed to fetch inventory item details with well-specific price:', error);
    return null;
  }
};

export const newExportObjectAsPDF = async (dataObject, token, shouldSendToBackend = false, existingDoc = null, downloadIndividual = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const doc = existingDoc || new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
        margins: { top: 10, bottom: 10, left: 10, right: 10 }
      });

      // Set the document font size for the header
      doc.setFontSize(12);
      const baseSpacing = 12; // Consistent spacing between sections
      doc.setFont('helvetica', 'normal');
      const rightColumnFontSize = 8; // Set the desired font size for the right column
      const rightColumnLineHeight = 4; // Set the desired line height for the right column

      doc.setFontSize(rightColumnFontSize);

      // Define left and right columns X positions
      const leftColumnX = 10;
      const rightColumnX = doc.internal.pageSize.getWidth() - 44; // X position for right column
      
      // Calculate the height of each line and the total header section height
      const lineHeight = 5; // approximate line height in points for the current font size
      const totalHeaderHeight = 15; // total height available for the header
      const headerY = (totalHeaderHeight / 2) - (lineHeight * 1.5) + 10; // adjust Y position to vertically center the three lines
      
      // Place the header text within the 15-point height space
      doc.text(`Ticket No. ${dataObject.ticket_number}`, leftColumnX + 5, headerY);
      doc.text('Plugger\'s', leftColumnX + 5, headerY + lineHeight);
      doc.text('Daily Work Record', leftColumnX + 5, headerY + 2 * lineHeight);
      doc.text(`${dataObject.day}`, leftColumnX + 5, headerY + 3 * lineHeight);

      
      // Place the address and contact information within the 15-point height space
      const rightColumnBaseY = headerY; // aligns with the top line of the left column
      doc.text('4706 Green Tree Blvd', rightColumnX, rightColumnBaseY);
      doc.text('Midland, TX 79707', rightColumnX, rightColumnBaseY + rightColumnLineHeight);
      doc.text('Office: (432) 618-1050', rightColumnX, rightColumnBaseY + 2 * rightColumnLineHeight);
      doc.text('Fax: (432) 231-0840', rightColumnX, rightColumnBaseY + 3 * rightColumnLineHeight);
      
      // Logo placement calculation
      const endOfLeftColumnX = leftColumnX + doc.getStringUnitWidth('Daily Work Record') * doc.internal.getFontSize();
      const startOfRightColumnX = rightColumnX;
      const logoWidth = 45; // Adjust as needed
      const logoHeight = 15; // As per the requirement
      const logoX = (endOfLeftColumnX + (startOfRightColumnX - endOfLeftColumnX) / 2 - logoWidth / 2) - 20; // Center the logo
      const logoY = headerY - 2; // Adjust Y position to match the top line of the text
      doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);
      
      doc.setFontSize(12);

      // Add "DBA: A-Plus P&A LLC" text below the logo
      const dbaText = 'DBA: A-Plus P&A LLC';
      const dbaFontSize = 10;
      const dbaTextWidth = doc.getStringUnitWidth(dbaText) * dbaFontSize;
      const dbaX = logoX + (logoWidth - dbaTextWidth) / 2 + 31;
      const dbaY = logoY + logoHeight + 7;
      doc.setFontSize(dbaFontSize);
      doc.setFont('helvetica', 'bold');
      doc.text(dbaText, dbaX, dbaY);

      // Reset font style
      doc.setFont('helvetica', 'normal');

      // Set up the starting point for the customer and well information table.
      const tableStartY = dbaY + 7;
      const tableMarginLeft = leftColumnX + 25;
      const tableMarginRight = rightColumnX;
      const pageWidth = doc.internal.pageSize.getWidth();

      console.log(dataObject);

      const customerInfo = [
        [
          { label: 'Customer Name:\n', value: dataObject.well?.customer?.name, colSpan: 2 },
          { label: 'Date:\n', value: dataObject.date, colSpan: 1 },
          { label: 'Plugger Unit No.:\n', value: dataObject.well?.assigned_rig.name, colSpan: 1 },
          { label: 'API:\n', value: dataObject.well?.api || '', colSpan: 1 },
          { label: 'RRC Job No.:\n', value: dataObject.well.rrc_job_number || '', colSpan: 1 },
          { label: 'AFE/PO No.:\n', value: dataObject.well.afe_po || '', colSpan: 1 }
        ],
        [
          { label: 'Lease and Well No.:\n', value: dataObject.well?.name || '', colSpan: 2 },
          { 
            label: 'Company Representative:\n',
            value: dataObject.contact && dataObject.contact.first_name && dataObject.contact.last_name
              ? `${dataObject.contact.first_name} ${dataObject.contact.last_name}`
              : '',
            colSpan: 2
          },
          { 
            label: 'Superintendent:\n', 
            value: dataObject.primary_manager 
              ? `${dataObject.primary_manager.first_name} ${dataObject.primary_manager.last_name}`
              : 'N/A',
            colSpan: 2
          },
          { 
            label: 'Supervisor:\n', 
            value: dataObject.created_by
              ? `${dataObject.created_by.first_name} ${dataObject.created_by.last_name}`
              : 'N/A',
            colSpan: 1 
          }
        ],
        [
          { label: 'County:\n', value: dataObject.well?.county || '', colSpan: 2 },
          { label: 'State:\n', value: dataObject.well?.state || '', colSpan: 2 },
          { label: 'Lattitude:\n', value: dataObject.well?.latitude || '', colSpan: 2 },
          { label: 'Longitude:\n', value: dataObject.well?.longitude || '', colSpan: 1 }
        ]
      ];

      autoTable(doc, {
        startY: tableStartY,
        theme: 'grid',
        styles: {
          fontSize: 8,
          cellPadding: 2,
          halign: 'left',
          textColor: [0, 0, 0], // Set text color to black
          lineWidth: 0.5, // Increased line weight for thicker borders
          lineColor: 0, // Explicitly setting line color to black
          overflow: 'linebreak',
          fontStyle: 'bold',
        }, // Text in the cells will be normal, only header is bold
        columnStyles: {
          0: { cellWidth: (pageWidth - 2 * tableMarginLeft) / 6 },
          1: { cellWidth: (pageWidth - 2 * tableMarginLeft) / 6 },
          2: { cellWidth: (pageWidth - 2 * tableMarginLeft) / 6 },
          3: { cellWidth: (pageWidth - 2 * tableMarginLeft) / 6 },
          4: { cellWidth: (pageWidth - 2 * tableMarginLeft) / 6 },
          5: { cellWidth: (pageWidth - 2 * tableMarginLeft) / 6 },
        },
        body: customerInfo.map(row => {
          let cells = [];
          let cellIndex = 0;
          row.forEach(item => {
            if (item.colSpan > 1) {
              cells.push({
                content: `${item.label} ${item.value}`,
                colSpan: item.colSpan,
                styles: { halign: 'left', fontStyle: 'bold' }
              });
              cellIndex += item.colSpan;
            } else {
              cells.push(`${item.label} ${item.value}`);
              cellIndex++;
            }
          });
          return cells;
        }),
        didParseCell: function (data) {
          if (data.section === 'body') {
            data.cell.styles.fontStyle = 'bold';
          }
        }
      });

      // Function to convert time from 24-hour to 12-hour format
      const convertTimeFormat = time24 => {
        const timeParts = time24.split(':');
        const hour = parseInt(timeParts[0], 10);
        const minutes = timeParts[1];
        const suffix = hour >= 12 ? 'PM' : 'AM';
        const hour12 = ((hour + 11) % 12) + 1; // Convert to 12-hour format
        return `${hour12.toString().padStart(2, '0')}:${minutes} ${suffix}`;
      };

      // Sort work assignments by from_time
      dataObject.work_assignments.sort((a, b) => a.from_time.localeCompare(b.from_time));

      // Prepare the work assignment data for the table
      const workAssignments = dataObject.work_assignments
        .sort((a, b) => a.from_time.localeCompare(b.from_time))
        .map(assignment => [
          convertTimeFormat(assignment.from_time),
          convertTimeFormat(assignment.to_time),
          assignment.description
        ]);

      // Set up the header for the work assignments table
      const workAssignmentHeader = [
        { content: 'From', styles: { fontStyle: 'bold' } },
        { content: 'To', styles: { fontStyle: 'bold' } },
        { content: 'Describe Work to 1/2 Hours - Separate Each Operation', styles: { fontStyle: 'bold' } }
      ];

      autoTable(doc, {
        startY: doc.lastAutoTable.finalY + 2, // Position table after the last one
        theme: 'grid',
        tableWidth: pageWidth - 2 * tableMarginLeft, // Set table width to fit within margins
        head: [workAssignmentHeader],
        headStyles: {
          fillColor: [255, 255, 255],
          fontStyle: 'bold',
          textColor: [0, 0, 0],
        },
        body: workAssignments,
        styles: {
          fontSize: 8,
          cellPadding: 2,
          halign: 'left',
          lineWidth: 0.5,
          lineColor: 0,
          overflow: 'linebreak',
          fontStyle: 'bold',
          textColor: [0, 0, 0],
        },
        columnStyles: {
          0: { cellWidth: 30 }, // Adjust the width for the "From" column
          1: { cellWidth: 30 }, // Adjust the width for the "To" column
          2: { cellWidth: pageWidth - 2 * tableMarginLeft - 18, overflow: 'linebreak' } // Set a fixed width for the description column and enable text wrapping
        }
      });

      const isChevronDJBasin = dataObject.well.customer.name === 'Chevron-DJ Basin';

      // Define separate arrays for on-turnkey and off-turnkey charge records
      let onTurnkeyChargeRecordData = [];
      let offTurnkeyChargeRecordData = [];

      // Make sure you await the fetching of details before attempting to populate chargeRecordData
      const itemDetailsMap = {}; // To avoid refetching the same items

      for (const record of dataObject.charge_records || []) {
        if (record.service_item_charges.length === 0 && record.inventory_item_charges.length === 0 && record.miscellaneous_item_charges.length === 0) {
          continue; // Skip this charge record if both arrays are empty
        }

        const chargeRecordData = record.on_turnkey ? onTurnkeyChargeRecordData : offTurnkeyChargeRecordData;

        for (const charge of record.service_item_charges || []) {
          const itemId = charge.service_item;
          if (!itemDetailsMap[itemId]) {
            itemDetailsMap[itemId] = await fetchServiceItemDetailsById(itemId, token);
          }
          const { name, unit_type, price } = itemDetailsMap[itemId] || {};
          chargeRecordData.push([
            name || 'N/A',
            charge.quantity_used,
            unit_type || 'N/A',
            record.on_turnkey ? '$0.00' : `$${price || 0}`,
            record.on_turnkey ? '$0.00' : `$${((parseFloat(price || 0) * parseFloat(charge.quantity_used)) || 0).toFixed(2)}`
          ]);
        }

        for (const charge of record.inventory_item_charges || []) {
          const itemId = charge.inventory_item;
          const wellId = dataObject.well.id;
          if (!itemDetailsMap[`${itemId}_${wellId}`]) {
            itemDetailsMap[`${itemId}_${wellId}`] = await fetchInventoryItemDetailsById(itemId, token, wellId);
          }
          const { name, unit_type, price } = itemDetailsMap[`${itemId}_${wellId}`] || {};
          chargeRecordData.push([
            name || 'N/A',
            charge.quantity_used,
            unit_type || 'N/A',
            record.on_turnkey ? '$0.00' : `$${price || 0}`,
            record.on_turnkey ? '$0.00' : `$${((parseFloat(price || 0) * parseFloat(charge.quantity_used)) || 0).toFixed(2)}`
          ]);
        }
        
        for (const charge of record.miscellaneous_item_charges || []) {
          const itemId = charge.miscellaneous_item_id;
          if (!itemDetailsMap[itemId]) {
            itemDetailsMap[itemId] = await fetchMiscellaneousItemDetailsById(itemId, token);
          }
          const { name, unit_type } = itemDetailsMap[itemId] || {};
          chargeRecordData.push([
            charge.custom_name || name || 'N/A',
            charge.quantity_used,
            unit_type || 'Each',
            record.on_turnkey ? '$0.00' : `$${charge.price_at_use || 0}`,
            record.on_turnkey ? '$0.00' : `$${((parseFloat(charge.price_at_use || 0) * parseFloat(charge.quantity_used)) || 0).toFixed(2)}`
          ]);
        }
      }
    

      // Calculate the totals
      const onTurnkeyTotal = onTurnkeyChargeRecordData.reduce((sum, row) => sum + parseFloat(row[4].replace('$', '')), 0);
      const offTurnkeyTotal = offTurnkeyChargeRecordData.reduce((sum, row) => sum + parseFloat(row[4].replace('$', '')), 0);

      // Add subtotal rows only if there are charge records
      if (onTurnkeyChargeRecordData.length > 0) {
        onTurnkeyChargeRecordData.push(['', '', '', 'Subtotal', '$0.00']);
      }
      if (offTurnkeyChargeRecordData.length > 0) {
        offTurnkeyChargeRecordData.push(['', '', '', 'Subtotal', `$${offTurnkeyTotal.toFixed(2)}`]);
      }

      // Function to create charge record table
      function createChargeRecordTable(doc, chargeRecordData, title) {
        if (chargeRecordData.length === 0) return; // Don't create table if there's no data

        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 2,
          theme: 'grid',
          head: [[
            { content: title, colSpan: 5, styles: { halign: 'center', fontStyle: 'bold' } }
          ], [
            { content: 'Description', styles: { fontStyle: 'bold' } },
            { content: 'Qty', styles: { fontStyle: 'bold' } },
            { content: 'Units', styles: { fontStyle: 'bold' } },
            { content: 'Rate', styles: { fontStyle: 'bold' } },
            { content: 'Total', styles: { fontStyle: 'bold' } },
          ]],
          body: chargeRecordData,
          headStyles: {
            fillColor: [255, 255, 255],
            fontStyle: 'bold',
            textColor: [0, 0, 0],
          },
          styles: {
            fontSize: 8,
            cellPadding: 2,
            halign: 'left',
            lineWidth: 0.5,
            lineColor: 0,
            overflow: 'linebreak',
            fontStyle: 'bold',
            textColor: [0, 0, 0],
          },
          columnStyles: {
            0: { cellWidth: 'auto' },
            1: { cellWidth: 'auto' },
            2: { cellWidth: 'auto' },
            3: { cellWidth: 'auto' },
            4: { cellWidth: 'auto' },
          },
          willDrawCell: data => {
            if (data.row.index === chargeRecordData.length - 1) {
              data.cell.styles.fontStyle = 'bold';
            }
            if (data.column.index > 0 && data.section !== 'head') {
              data.cell.styles.halign = 'right';
            }
          },
        });
      }

      // Create tables for on-turnkey and off-turnkey charge records only if they have data
      if (onTurnkeyChargeRecordData.length > 0) {
        createChargeRecordTable(doc, onTurnkeyChargeRecordData, 'On Turnkey Charges');
      }
      if (offTurnkeyChargeRecordData.length > 0) {
        createChargeRecordTable(doc, offTurnkeyChargeRecordData, 'Off Turnkey Charges');
      }

      // Calculate and add the grand total only if there are any charge records
      if (onTurnkeyChargeRecordData.length > 0 || offTurnkeyChargeRecordData.length > 0) {
        const grandTotal = onTurnkeyTotal + offTurnkeyTotal;
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 2,
          theme: 'grid',
          body: [['', '', '', 'Grand Total', `$${grandTotal.toFixed(2)}`]],
          styles: {
            fontSize: 8,
            cellPadding: 2,
            halign: 'left',
            lineWidth: 0.5,
            lineColor: 0,
            overflow: 'linebreak',
            fontStyle: 'bold',
            textColor: [0, 0, 0],
          },
          columnStyles: {
            0: { cellWidth: 'auto' },
            1: { cellWidth: 'auto' },
            2: { cellWidth: 'auto' },
            3: { cellWidth: 'auto' },
            4: { cellWidth: 'auto' },
          },
          willDrawCell: data => {
            data.cell.styles.fontStyle = 'bold';
            if (data.column.index > 0) {
              data.cell.styles.halign = 'right';
            }
          },
        });
      }

      // Define the base order for job titles
      const jobTitleBaseOrder = {
        "Rig Supervisor": 1,
        "Reclamation Supervisor": 2,
        "Rig Operator": 3,
        "Heavy Equipment Operator": 4,
        "Derrick Hand": 5,
        "Driver": 6,
        "Floor Hand": 7,
        "Spotter": 8 // Base order for the first Floor Hand  // Base order for the first 'Other'
      };

      // Object to keep track of occurrences
      let jobTitleOccurrences = {
        "Floor Hand": 0,
      };

      // Function to get the next available order for a job title
      function getNextJobTitleOrder(jobTitle) {
        if (jobTitle in jobTitleOccurrences) {
          const count = jobTitleOccurrences[jobTitle]++;
          return jobTitleBaseOrder[jobTitle] + count; // Increment the base order based on count
        }
        return jobTitleBaseOrder[jobTitle] || 7; // Default to 7 if not in base order
      }

      // Convert job_title from snake_case to "Job Title" format
      function formatJobTitle(jobTitle) {
        const words = jobTitle.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return words.join(' ');
      }

      // Check if employee_time_records exists before attempting to sort
      if (dataObject.time_record && dataObject.time_record.employee_time_records) {
        dataObject.time_record.employee_time_records.sort((a, b) => {
          let orderA = getNextJobTitleOrder(formatJobTitle(a.employee.job_title));
          let orderB = getNextJobTitleOrder(formatJobTitle(b.employee.job_title));
          return orderA - orderB;
        });
      }

      const timeRecordData = dataObject.time_record?.employee_time_records?.map(record => [
        formatJobTitle(record.employee.job_title), // Use the formatted job title
        `${record.employee.first_name} ${record.employee.last_name}`,
        convertTimeFormat(record.start_time),
        convertTimeFormat(record.end_time),
        record.rig_time,
        record.travel_time,
        record.npt_company_time,
        record.npt_customer_time,
        `${parseFloat(record.rig_time) + parseFloat(record.travel_time) + parseFloat(record.npt_company_time) + parseFloat(record.npt_customer_time)}`,
      ]) || [];

      // Calculate the total for each column in the Time Record section
      const rigTotal = timeRecordData.reduce((sum, row) => sum + parseFloat(row[4]), 0);
      const travelTotal = timeRecordData.reduce((sum, row) => sum + parseFloat(row[5]), 0);
      const nptJMRTotal = timeRecordData.reduce((sum, row) => sum + parseFloat(row[6]), 0);
      const nptCustTotal = timeRecordData.reduce((sum, row) => sum + parseFloat(row[7]), 0);
      const totalHours = timeRecordData.reduce((sum, row) => sum + parseFloat(row[8]), 0);

      // Append the total row to the timeRecordData array
      timeRecordData.push([
        '',
        'Total Hours (Extended)',
        '',
        '',
        rigTotal.toFixed(2),
        travelTotal.toFixed(2),
        nptJMRTotal.toFixed(2),
        nptCustTotal.toFixed(2),
        totalHours.toFixed(2),
      ]);

      autoTable(doc, {
        startY: doc.lastAutoTable.finalY + 2,
        theme: 'grid',
        head: [[
          { content: 'Job Title', styles: { fontStyle: 'bold' } },
          { content: 'Employee Name', styles: { fontStyle: 'bold' } },
          { content: 'Start', styles: { fontStyle: 'bold' } },
          { content: 'Stop', styles: { fontStyle: 'bold' } },
          { content: 'Rig', styles: { fontStyle: 'bold' } },
          { content: 'Travel', styles: { fontStyle: 'bold' } },
          { content: 'NPT JMR', styles: { fontStyle: 'bold' } },
          { content: 'NPT Cust.', styles: { fontStyle: 'bold' } },
          { content: 'Total', styles: { fontStyle: 'bold' } },
        ]],
        headStyles: {
          fillColor: [255, 255, 255],
          fontStyle: 'bold',
          textColor: [0, 0, 0],
        },
        body: timeRecordData,
        styles: {
          fontSize: 8,
          cellPadding: 2,
          halign: 'left',
          lineWidth: 0.5,
          lineColor: 0,
          overflow: 'linebreak',
          fontStyle: 'bold',
          textColor: [0, 0, 0], // Set text color to black

        },
        columnStyles: {
          0: { cellWidth: 'auto' },
          1: { cellWidth: 'auto' },
          2: { cellWidth: 'auto' },
          3: { cellWidth: 'auto' },
          4: { cellWidth: 'auto' },
          5: { cellWidth: 'auto' },
          6: { cellWidth: 'auto' },
          7: { cellWidth: 'auto' },
          8: { cellWidth: 'auto' },
        },
        willDrawCell: data => {
          if (data.row.index === timeRecordData.length - 1) {
            data.cell.styles.fontStyle = 'bold';
          }
          if (data.column.index >= 4 && data.section !== 'head') {
            data.cell.styles.halign = 'right';
          }
        },
      });

      const disclaimer = 'Disclaimer:\nServices have been provided subject to the terms and conditions on the current price list, the receipt of which is hereby acknowledged. Customer agrees to pay all invoices for services on a net 30 day basis from date on which purchases were invoiced. Customer agrees that JMR Services shall not be liable for damages that occur "down hole", any loss of production, any loss of oil in place or any other consequential damages. If customer disputes any item invoiced, customer must, within twenty (20) days after receipt of invoice, notify contractor of item disputed specifying reason therefor, but law in the state where services were performed. All expenses of collection including court costs and reasonable attorney fees will be borne by customer. All amounts due hereunder are payable in Midland, Midland County, Texas.';

      autoTable(doc, {
        startY: doc.lastAutoTable.finalY + 2,
        theme: 'grid',
        body: [[disclaimer]],
        styles: {
          fontSize: 8,
          cellPadding: 2,
          halign: 'left',
          lineWidth: 0.5,
          lineColor: 0,
          overflow: 'linebreak',
          textColor: [0, 0, 0], // Set text color to black

        },
        columnStyles: {
          0: { cellWidth: doc.lastAutoTable.width },
        },
      });

      if (dataObject.notes) {
        autoTable(doc, {
          startY: doc.lastAutoTable.finalY + 2,
          theme: 'grid',
          body: [
            [{
              content: 'Supervisor Notes:',
              styles: {
                fontStyle: 'bold',
                textDecoration: 'underline',
              },
            }],
            [dataObject.notes],
          ], styles: {
            fontSize: 8,
            cellPadding: 2,
            halign: 'left',
            lineWidth: 0.5,
            lineColor: 0,
            overflow: 'linebreak',
            textColor: [0, 0, 0], // Set text color to black
          },
          columnStyles: {
            0: { cellWidth: doc.lastAutoTable.width },
          },
        });
      }

      // Safety check and set default if not defined
      if (!doc.internal.margins) {
        doc.internal.margins = { top: 10, bottom: 10, left: 10, right: 10 };
      }

      // Check for remaining space and add a new page if needed
      const pageHeight = doc.internal.pageSize.getHeight();
      const startY = doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY + 2 : 20; // Use a default startY if finalY is undefined
      const necessarySpace = 30; // Estimated necessary space for the next table

      if (pageHeight - startY < necessarySpace) {
        doc.addPage();
      }

      // Add signature placeholders
      const signatureFields = [
        { name: 'CustomerRepresentative', x: leftColumnX + 5, y: doc.lastAutoTable.finalY + 20, width: 100, height: 10 },
        { name: 'JmrRepresentative', x: leftColumnX + 5, y: doc.lastAutoTable.finalY + 35, width: 100, height: 10 },
      ];

      signatureFields.forEach(field => {
        if (field.name === 'CustomerRepresentative') {
          doc.setFontSize(12);
          doc.text('Customer Representative:', field.x, field.y);
        } else if (field.name === 'JmrRepresentative') {
          doc.setFontSize(12);
          doc.text('JMR Representative:', field.x, field.y);
        }
      });

      // Use safe default width calculations
      const margin = doc.internal.margins;
      const effectivePageWidth = pageWidth - margin.left - margin.right;
      const defaultWidth = effectivePageWidth / 2; // Half width for each of the two columns

      autoTable(doc, {
        startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 40 : 60,
        theme: 'plain', // 'plain' theme to avoid any additional styling
        body: [
          ['', ''], // Empty row for spacing
          ['', ''], // Empty row for spacing
        ],
        styles: {
          fontSize: 12,
          cellPadding: 4,
          halign: 'left',
          lineWidth: 0, // Set to zero to remove borders
          lineColor: 0,
          overflow: 'linebreak',
          textColor: [0, 0, 0], // Set text color to black
        },
        columnStyles: {
          0: { cellWidth: 'auto' },
          1: { cellWidth: 'auto' },
        },
        willDrawCell: data => {
          // Conditionally remove borders by setting the line width to 0 inside this hook
          data.cell.styles.lineWidth = 0; // Further ensure no borders are drawn
        },
      });

      const pdfBlob = doc.output('blob');

      if (shouldSendToBackend) {
        await sendPdfToBackend(pdfBlob, token);
        resolve(pdfBlob);
      } else if (downloadIndividual && !existingDoc) {
        // Only download the PDF if downloadIndividual is true
        const wellName = dataObject.well?.name || 'Unknown_Well';
        const dwrDate = dataObject.date ? new Date(dataObject.date).toISOString().split('T')[0] : 'Unknown_Date';
        const filename = `${wellName} - ${dwrDate} Daily Work Record.pdf`;
        doc.save(filename);
        resolve(pdfBlob);
      } else {
        resolve(pdfBlob);
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
      reject(error);
    }
  });
};

// New function to combine multiple PDFs
export const exportMultipleDWRsAsCombinedPDF = async (dwrList, token) => {
  try {
    const combinedPdf = await PDFDocument.create();
    let wellNames = new Set();
    let rigNames = new Set();

    for (let i = 0; i < dwrList.length; i++) {
      const dwr = dwrList[i];
      console.log(`Processing DWR ID: ${dwr.id}`);

      const pdfBlob = await newExportObjectAsPDF(dwr, token, false, null, false); // Ensure downloadIndividual is false
      if (!pdfBlob) {
        throw new Error(`Failed to generate PDF blob for DWR ID: ${dwr.id}`);
      }

      console.log(`PDF blob generated for DWR ID: ${dwr.id}`);
      const pdfArrayBuffer = await pdfBlob.arrayBuffer();
      if (!pdfArrayBuffer) {
        throw new Error(`Failed to convert PDF blob to ArrayBuffer for DWR ID: ${dwr.id}`);
      }

      console.log(`ArrayBuffer created for DWR ID: ${dwr.id}`);
      const pdfDoc = await PDFDocument.load(pdfArrayBuffer);
      if (!pdfDoc) {
        throw new Error(`Failed to load PDF document for DWR ID: ${dwr.id}`);
      }

      console.log(`PDF document loaded for DWR ID: ${dwr.id}`);
      const copiedPages = await combinedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach((page) => {
        combinedPdf.addPage(page);
      });

      console.log(`Pages copied for DWR ID: ${dwr.id}`);

      if (dwr.well?.name) {
        wellNames.add(dwr.well.name);
      }
      if (dwr.well?.assigned_rig?.name) {
        rigNames.add(dwr.well.assigned_rig.name);
      }
    }

    const combinedPdfBytes = await combinedPdf.save();
    console.log('Combined PDF bytes generated');
    const combinedPdfBlob = new Blob([combinedPdfBytes], { type: 'application/pdf' });
    console.log('Combined PDF blob created');

    // Create the filename
    const wellNamesStr = Array.from(wellNames).join('_');
    const rigNamesStr = Array.from(rigNames).join('_');
    const filename = `Combined_Daily_Work_Records_${wellNamesStr}_${rigNamesStr}_${new Date().toISOString().split('T')[0]}.pdf`;

    // Automatically download the combined PDF
    const link = document.createElement('a');
    link.href = URL.createObjectURL(combinedPdfBlob);
    link.download = filename;
    document.body.appendChild(link); // Append link to the body
    link.click();
    document.body.removeChild(link); // Remove link from the body

    return combinedPdfBlob;
  } catch (error) {
    console.error('Failed to combine and download PDFs:', error);
    throw error;
  }
};
