import React, { useState, useEffect } from 'react';
import { Modal, Box, Checkbox, FormControlLabel,  } from '@mui/material';
import axios from 'axios';
import '../styles/WorkDescriptionsModal.css'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const WorkDescriptionsModal = ({ open, handleClose, setSelectedWorkDescriptions, workDescriptions }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({});

  const toggleGroup = (group) => {
    setExpandedGroups((prevState) => ({
      ...prevState,
      [group]: !prevState[group],
    }));
  };

  const groupedWorkDescriptions = workDescriptions.reduce((acc, description) => {
    if (!acc[description.group]) {
      acc[description.group] = [];
    }
    acc[description.group].push(description);
    return acc;
  }, {});
  // console.log(workDescriptions); // Add this in each component

  const handleSelect = (id) => {
    const currentIndex = selectedIds.indexOf(id);
    const newChecked = [...selectedIds];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedIds(newChecked);
  };

  const handleSubmit = () => {
    setSelectedWorkDescriptions(selectedIds);
    handleClose();
  };

  useEffect(() => {
    setExpandedGroups({});
    setSelectedIds([]);
  }, [open]);


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="work-descriptions-modal-title"
      aria-describedby="work-descriptions-modal-description"
    >
      <Box sx={modalStyle}>
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {Object.keys(groupedWorkDescriptions).map((group) => (
            <div key={group}>
              <button onClick={() => toggleGroup(group)}>
                {expandedGroups[group] ? '-' : '+'} {group}
              </button>
              {expandedGroups[group] && (
                <div>
                  {groupedWorkDescriptions[group].map((descriptionItem) => (
                    <FormControlLabel
                      key={descriptionItem.id}
                      control={
                        <Checkbox
                          checked={selectedIds.includes(descriptionItem.id)}
                          onChange={() => handleSelect(descriptionItem.id)}
                        />
                      }
                      label={descriptionItem.description}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <button className="btn hover" onClick={handleSubmit}>
          Add Selected
        </button>
      </Box>
    </Modal>
  );
};

export default WorkDescriptionsModal;
