import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logo from '../assets/JMR-Logo.png';

import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { styled } from '@mui/system';

const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const StyledBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: '#f5f5f5',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 200,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    margin: theme.spacing(1),
    width: 200,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: '#0b6e4f',
    color: 'white',
    '&:hover': {
        backgroundColor: '#095a41',
    },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const NPTReportView = () => {
    const [selectedRig, setSelectedRig] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [reportData, setReportData] = useState(null);
    const [rigs, setRigs] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedRigName, setSelectedRigName] = useState('');
    const [selectedCustomerName, setSelectedCustomerName] = useState('');
    const [reportType, setReportType] = useState('rig'); // 'rig' or 'customer'

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString("en-US", { timeZone: "UTC" });
    };

    const formatTime = (dateString, timeString) => {
        const dateTime = new Date(`${dateString}T${timeString}Z`);
        return dateTime.toLocaleTimeString("en-US", { timeZone: "America/Chicago", hour: '2-digit', minute: '2-digit' });
    };

    const calculateTotalHours = (fromTime, toTime) => {
        const fromDate = new Date(`2000-01-01T${fromTime}Z`);
        const toDate = new Date(`2000-01-01T${toTime}Z`);
        const diffInMinutes = (toDate - fromDate) / 60000;
        return (diffInMinutes / 60).toFixed(2);
    };

    const handleSearch = async () => {
        try {
            const token = localStorage.getItem('my-jwt');
            const response = await axios.get(`${appUrl}/jmrapp/dailyworkrecords/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    rig: reportType === 'rig' ? selectedRig : undefined,
                    customer: reportType === 'customer' ? selectedCustomer : undefined,
                    detail: 'true',
                    start_date: startDate,
                    end_date: endDate
                }
            });

            const filteredDwrs = response.data;

            const reportData = {};

            filteredDwrs.forEach(dwr => {
                const dwrDate = new Date(dwr.date);
                const startDateObj = new Date(startDate);
                const endDateObj = new Date(endDate);

                if (dwrDate >= startDateObj && dwrDate <= endDateObj) {
                    const key = reportType === 'rig' ? dwr.well.name : dwr.well.assigned_rig.name;
                    
                    const filteredWorkAssignments = dwr.work_assignments.filter(assignment =>
                        assignment.description.toLowerCase().includes('jmr npt') ||
                        assignment.description.toLowerCase().includes('customer npt')
                    );

                    if (filteredWorkAssignments.length > 0) {
                        if (!reportData[key]) {
                            reportData[key] = {
                                customer: dwr.well.customer.name,
                                nptData: [],
                            };
                        }

                        reportData[key].nptData.push({
                            date: dwr.date,
                            workAssignments: filteredWorkAssignments.map(assignment => ({
                                description: assignment.description,
                                fromTime: assignment.from_time,
                                toTime: assignment.to_time,
                            })),
                        });
                    }
                }
            });

            setReportData(reportData);
        } catch (error) {
            console.error('Error fetching report data:', error);
        }
    };



    const handleDownloadPDF = async () => {
        try {
            const doc = new jsPDF({
                orientation: 'p',
                unit: 'mm',
                format: 'a4',
                margins: { top: 10, bottom: 10, left: 10, right: 10 }
            });

            // Set up constants
            const pageWidth = doc.internal.pageSize.getWidth();
            const leftColumnX = 10;
            const rightColumnX = pageWidth - 44;
            const baseSpacing = 12;
            const headerY = 10;

            // Header section
            doc.setFontSize(8);
            doc.setFont('helvetica', 'normal');

            // Left column
            doc.text(`${reportType === 'rig' ? `Rig Number ${selectedRigName}` : `Customer: ${selectedCustomerName}`}`, leftColumnX + 5, headerY);
            doc.text('NPT Report', leftColumnX + 5, headerY + 4);
            doc.text(`${startDate} - ${endDate}`, leftColumnX + 5, headerY + 8);

            // Right column
            doc.setFontSize(8);
            doc.text('4706 Green Tree Blvd', rightColumnX, headerY);
            doc.text('Midland, TX 79707', rightColumnX, headerY + 4);
            doc.text('Office: (432) 618-1050', rightColumnX, headerY + 8);
            doc.text('Fax: (432) 231-0840', rightColumnX, headerY + 12);

            // Logo
            const logoWidth = 45;
            const logoHeight = 15;
            const logoX = (pageWidth - logoWidth) / 2;
            const logoY = headerY - 2;
            doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

            // DBA text
            const dbaText = 'DBA: A-Plus P&A LLC';
            const dbaFontSize = 10;
            const dbaTextWidth = doc.getStringUnitWidth(dbaText) * dbaFontSize;
            const dbaX = logoX + ((logoWidth - dbaTextWidth) / 2) + 31;
            const dbaY = logoY + logoHeight + 7;
            doc.setFontSize(dbaFontSize);
            doc.setFont('helvetica', 'bold');
            doc.text(dbaText, dbaX, dbaY);

            // Reset font
            doc.setFont('helvetica', 'normal');

            let yOffset = dbaY + 15;

            // Function to add a new page if needed
            const addNewPageIfNeeded = (height) => {
                if (yOffset + height > doc.internal.pageSize.getHeight() - 20) {
                    doc.addPage();
                    yOffset = 20;
                    return true;
                }
                return false;
            };

            // Calculate total NPT time across all DWRs
            let totalNPTTime = 0;

            // Iterate through each entry in the report data
            Object.entries(reportData).forEach(([key, info]) => {
                addNewPageIfNeeded(60);

                const startY = yOffset;

                // First table: Customer Name, Well Name/Rig Name, Date Range
                autoTable(doc, {
                    startY: yOffset,
                    head: [['Customer Name', reportType === 'rig' ? 'Well Name' : 'Rig Name', 'Date Range']],
                    body: [[info.customer, key, `${startDate} - ${endDate}`]],
                    styles: {
                        fontSize: 8,
                        cellPadding: 2,
                        lineWidth: 0.5,
                        lineColor: 0,
                        textColor: [0, 0, 0],
                    },
                    headStyles: {
                        fillColor: [11, 110, 79], // #0b6e4f
                        textColor: [255, 255, 255],
                        fontStyle: 'bold',
                    },
                });

                yOffset = doc.lastAutoTable.finalY + 5;

                // NPT data table
                const nptTableData = info.nptData.flatMap(nptEntry => 
                    nptEntry.workAssignments.map(assignment => {
                        const hours = calculateTotalHours(assignment.fromTime, assignment.toTime);
                        totalNPTTime += parseFloat(hours);
                        return [
                            formatDate(nptEntry.date),
                            assignment.description,
                            assignment.fromTime ? formatTime(nptEntry.date, assignment.fromTime) : '',
                            assignment.toTime ? formatTime(nptEntry.date, assignment.toTime) : '',
                            hours
                        ];
                    })
                );

                autoTable(doc, {
                    startY: yOffset,
                    head: [['Date', 'Description', 'From', 'To', 'Hours']],
                    body: nptTableData,
                    styles: {
                        fontSize: 8,
                        cellPadding: 2,
                        lineWidth: 0.5,
                        lineColor: 0,
                        textColor: [0, 0, 0],
                    },
                    headStyles: {
                        fillColor: [11, 110, 79], // #0b6e4f
                        textColor: [255, 255, 255],
                        fontStyle: 'bold',
                    },
                });

                yOffset = doc.lastAutoTable.finalY + 5;

                // Draw a border around the well's data
                const endY = yOffset;
                doc.setDrawColor(0);
                doc.setLineWidth(0.5);
                doc.rect(10, startY - 5, doc.internal.pageSize.width - 20, endY - startY + 10);

                yOffset += 10; // Add some space after the well's data
            });

            // Add total NPT time at the bottom of the report
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.text(`Total NPT Time: ${totalNPTTime.toFixed(2)} hours`, 10, yOffset);

            // Generate and download PDF
            const pdfBlob = doc.output('blob');
            const url = URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `NPT_Report_${reportType === 'rig' ? selectedRigName : selectedCustomerName}_${startDate}_to_${endDate}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error generating NPT Report PDF:', error);
        }
    };
      
    useEffect(() => {
        const fetchRigs = async () => {
            try {
                const token = localStorage.getItem('my-jwt');
                const response = await axios.get(`${appUrl}/jmrapp/rigs/`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setRigs(response.data);
            } catch (error) {
                console.error('Error fetching rigs:', error);
            }
        };

        const fetchCustomers = async () => {
            try {
                const token = localStorage.getItem('my-jwt');
                const response = await axios.get(`${appUrl}/jmrapp/customers/`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setCustomers(response.data);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchRigs();
        fetchCustomers();
    }, []);


    useEffect(() => {
        const selectedRigObj = rigs.find(rig => rig.id === selectedRig);
        setSelectedRigName(selectedRigObj ? selectedRigObj.name : '');
    }, [selectedRig, rigs]);

    useEffect(() => {
        const selectedCustomerObj = customers.find(customer => customer.id === selectedCustomer);
        setSelectedCustomerName(selectedCustomerObj ? selectedCustomerObj.name : '');
    }, [selectedCustomer, customers]);


    // const handleSearch = async () => {
    //     try {
    //         const token = localStorage.getItem('my-jwt');
    //         const response = await axios.get(`${appUrl}/jmrapp/dailyworkrecords/`, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             },
    //             params: {
    //                 rig: selectedRig,
    //                 detail: 'true',
    //                 start_date: startDate,
    //                 end_date: endDate
    //             }
    //         });

    //         const filteredDwrs = response.data;

    //         const wellData = {};

    //         filteredDwrs.forEach(dwr => {
    //             const wellName = dwr.well.name;
    //             if (!wellData[wellName]) {
    //                 wellData[wellName] = {
    //                     customer: dwr.well.customer.name,
    //                     nptData: [],
    //                 };
    //             }

    //             const filteredWorkAssignments = dwr.work_assignments.filter(assignment =>
    //                 assignment.description.toLowerCase().includes('jmr npt') ||
    //                 assignment.description.toLowerCase().includes('customer npt')
    //             );

    //             if (filteredWorkAssignments.length > 0) {
    //                 wellData[wellName].nptData.push({
    //                     date: dwr.date,
    //                     workAssignments: filteredWorkAssignments.map(assignment => ({
    //                         description: assignment.description,
    //                         fromTime: assignment.from_time,
    //                         toTime: assignment.to_time,
    //                     })),
    //                 });
    //             }
    //         });

    //         setReportData(wellData);
    //     } catch (error) {
    //         console.error('Error fetching report data:', error);
    //     }
    // };

    return (
        <StyledBox>
            <Typography variant="h4" gutterBottom sx={{ color: '#0b6e4f', fontWeight: 'bold' }}>
                NPT Reporting
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginBottom: 3 }}>
                <StyledFormControl>
                    <InputLabel>Report Type</InputLabel>
                    <Select
                        value={reportType}
                        onChange={(e) => setReportType(e.target.value)}
                    >
                        <MenuItem value="rig">By Rig</MenuItem>
                        <MenuItem value="customer">By Customer</MenuItem>
                    </Select>
                </StyledFormControl>
    
                {reportType === 'rig' && (
                    <StyledFormControl>
                        <InputLabel>Select Rig</InputLabel>
                        <Select
                            value={selectedRig}
                            onChange={(e) => setSelectedRig(e.target.value)}
                        >
                            <MenuItem value="">-- Select a Rig --</MenuItem>
                            {rigs.sort((a, b) => a.name.localeCompare(b.name)).map((rig) => (
                                <MenuItem key={rig.id} value={rig.id}>{rig.name}</MenuItem>
                            ))}
                        </Select>
                    </StyledFormControl>
                )}
    
                {reportType === 'customer' && (
                    <StyledFormControl>
                        <InputLabel>Select Customer</InputLabel>
                        <Select
                            value={selectedCustomer}
                            onChange={(e) => setSelectedCustomer(e.target.value)}
                        >
                            <MenuItem value="">-- Select a Customer --</MenuItem>
                            {customers.sort((a, b) => a.name.localeCompare(b.name)).map((customer) => (
                                <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                            ))}
                        </Select>
                    </StyledFormControl>
                )}
    
                <StyledTextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <StyledTextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 3 }}>
                <StyledButton variant="contained" onClick={handleSearch}>
                    Generate Report
                </StyledButton>
                <StyledButton variant="contained" onClick={handleDownloadPDF}>
                    Download PDF
                </StyledButton>
            </Box>
            {reportData && (
                <Box mt={3}>
                    <Typography variant="h5" gutterBottom sx={{ color: '#0b6e4f', fontWeight: 'bold' }}>
                        {reportType === 'rig' ? `${selectedRigName} NPT Report` : `${selectedCustomerName} NPT Report`}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {formatDate(startDate)} - {formatDate(endDate)}
                    </Typography>
                    {Object.entries(reportData).map(([key, info]) => (
                        <StyledPaper key={key}>
                            <Typography variant="h6" gutterBottom sx={{ color: '#0b6e4f' }}>
                                {reportType === 'rig' ? `${info.customer} - ${key}` : `${key} - ${info.customer}`}
                            </Typography>
                            {info.nptData.length > 0 ? (
                                info.nptData.map(nptEntry => (
                                    <Box key={nptEntry.date} mb={2}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Date: {formatDate(nptEntry.date)}
                                        </Typography>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow sx={{ backgroundColor: '#0b6e4f' }}>
                                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
                                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>From Time</TableCell>
                                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>To Time</TableCell>
                                                        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Total (Hours)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {nptEntry.workAssignments.map((assignment, assignmentIndex) => (
                                                        <TableRow key={assignmentIndex} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' } }}>
                                                            <TableCell>{assignment.description}</TableCell>
                                                            <TableCell>{formatTime(nptEntry.date, assignment.fromTime)}</TableCell>
                                                            <TableCell>{formatTime(nptEntry.date, assignment.toTime)}</TableCell>
                                                            <TableCell>
                                                                {calculateTotalHours(assignment.fromTime, assignment.toTime)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                ))
                            ) : (
                                <Typography>No NPT Reported</Typography>
                            )}
                        </StyledPaper>
                    ))}
                </Box>
            )}
        </StyledBox>
    );
};

export default NPTReportView;
