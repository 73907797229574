import axios from 'axios';
import * as XLSX from 'xlsx';

const appUrl = process.env.REACT_APP_APP_URL;
if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const generateUniqueId = () => {
    const date = new Date();
    return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}`;
    // Returns something like: "20241023_1230"
  };

// Template configuration object
const templateConfigs = {

        2438: { //Find diamondback ID
        fileName: "diamondback_oi_template.xlsx",
        formatData: (item, index, tranId) => ({
            'A': tranId || '',  // Use tran_id from helper function
            'B': item.date || '',
            'C': 'JMRServices_Wireline_PROD',  // PriceBook Name
            'D': 'USD',  // Currency Code set to USD for every line
            'E': item.contact.first_name + ' ' + item.contact.last_name || '',
            'F': 'Plug And Abandon Well',  // Description set to ticket number
            'G': item.assigned_rig_name || '',
            'H': '',  // PO
            'I': item.assigned_rig_name || '',
            'J': (index + 1).toString(),  // Line Number set to sequential value starting with 1
            'K': '',  // Line Service Date From
            'L': '',  // Line Service Date To
            'O': item.item_name || '',
            'P': item.item_name || '',
            'Q': item.well_name || '',
            'R': item.item_quantity || '',
            'S': item.item_unit_type || '',  // Qualifier
            'T': item.item_price || '',  // Ensure item_price is correctly used
            'U': '',
            'V': '',
            'W': item.type || '',
            'X': item.work_assignment_description || '',
            'Y': '',  // Work Order No.,
            'AA': '0' || '',
            'AB': '0' || '',
            'AC': 'Bbl' || '',
            'AF': 'None' || ''
        }),
    },
    
    1074: { //Find conoco ID
        fileName: "conoco_oi_template.xlsx",
        formatData: (item, index, tranId) => ({
            'A': tranId || '',  // Use tran_id from helper function
            'B': item.date || '',
            'C': 'JMRServices_Wireline_PROD',  // PriceBook Name
            'D': 'USD',  // Currency Code set to USD for every line
            'E': item.contact.first_name + ' ' + item.contact.last_name || '',
            'F': 'Plug And Abandon Well',  // Description set to ticket number
            'G': item.assigned_rig_name || '',
            'H': '',  // PO
            'I': item.assigned_rig_name || '',
            'J': (index + 1).toString(),  // Line Number set to sequential value starting with 1
            'K': '',  // Line Service Date From
            'L': '',  // Line Service Date To
            'O': item.item_name || '',
            'P': item.item_name || '',
            'Q': item.well_name || '',
            'R': item.item_quantity || '',
            'S': item.item_unit_type || '',  // Qualifier
            'T': item.item_price || '',  // Ensure item_price is correctly used
            'U': '',
            'V': '',
            'W': item.type || '',
            'X': item.work_assignment_description || '',
            'Y': '',  // Work Order No.,
            'AA': '0' || '',
            'AB': '0' || '',
            'AC': 'Bbl' || '',
            'AF': 'None' || ''
        }),
    },
    1080: {
        fileName: "endeavor_oi_template.xlsx",
        formatData: (item, index, tranId) => ({
            'A': tranId || '',  // Use tran_id from helper function
            'B': item.date || '',
            'C': 'JMRServices_Wireline_PROD',  // PriceBook Name
            'D': 'USD',  // Currency Code set to USD for every line
            'E': item.contact.first_name + ' ' + item.contact.last_name || '',
            'F': 'Plug And Abandon Well',  // Description set to ticket number
            'G': item.assigned_rig_name || '',
            'H': '',  // PO
            'I': item.assigned_rig_name || '',
            'J': (index + 1).toString(),  // Line Number set to sequential value starting with 1
            'K': '',  // Line Service Date From
            'L': '',  // Line Service Date To
            'O': item.item_name || '',
            'P': item.item_name || '',
            'Q': item.well_name || '',
            'R': item.item_quantity || '',
            'S': item.item_unit_type || '',  // Qualifier
            'T': item.item_price || '',  // Ensure item_price is correctly used
            'U': '',
            'V': '',
            'W': item.type || '',
            'X': item.work_assignment_description || '',
            'Y': '',  // Work Order No.,
            'AA': '0' || '',
            'AB': '0' || '',
            'AC': 'Bbl' || '',
            'AF': 'None' || ''
        }),
    },
    2583: {
        fileName: "devon_oi_template.xlsx",
        formatData: (item, index, tranId) => ({
            'A': tranId || '',  // Use tran_id from helper function
            'B': item.date || '',
            'C': '',  // PriceBook Name
            'D': 'USA',  // Currency Code set to USA for every line
            'E': item.created_by || '',
            'F': item.item_name,  // Description set to ticket number
            'G': (index + 1).toString(),  // Line Number set to sequential value starting with 1
            'H': '',  // PO
            'I': item.well_id || '',
            'J': item.well_name || '',
            'K': '',  // Line Service Date From
            'L': '',  // Line Service Date To
            'M': item.item_netsuite_id || '',
            'N': item.item_custom_name || '',
            'O': item.Location || '',
            'P': item.item_quantity || '',
            'Q': '',  // Units
            'R': item.item_price || '',  // Ensure item_price is correctly used
            'S': '',  // Qualifier
            'T': tranId || '',  // Placeholder Value, Transaction ID
            'U': item.well_netsuite_id || '',
            'V': item.memo || '',
            'W': item.type || '',
            'X': item.work_assignment_description || '',
            'Y': ''  // Work Order No.
        }),
    },
    // Add more configurations for other customer IDs
    default: {
        fileName: "default_oi_template.xlsx",
        formatData: (item, index, tranId) => ({
            'A': tranId || '',  // Use tran_id from helper function
            'B': item.date || '',
            'C': '',  // PriceBook Name
            'D': 'USA',  // Currency Code set to USA for every line
            'E': item.created_by || '',
            'F': item.item_name,  // Description set to ticket number
            'G': (index + 1).toString(),  // Line Number set to sequential value starting with 1
            'H': '',  // PO
            'I': item.well_id || '',
            'J': item.well_name || '',
            'K': '',  // Line Service Date From
            'L': '',  // Line Service Date To
            'M': item.item_netsuite_id || '',
            'N': item.item_custom_name || '',
            'O': item.Location || '',
            'P': item.item_quantity || '',
            'Q': '',  // Units
            'R': item.item_price || '',  // Ensure item_price is correctly used
            'S': '',  // Qualifier
            'T': tranId || '',  // AFE
            'U': item.well_netsuite_id || '',
            'V': item.memo || '',
            'W': item.type || '',
            'X': item.work_assignment_description || '',
            'Y': ''  // Work Order No.
        }),
    },
};

// Function to get SuiteTalk token
const getSuiteTalkToken = async () => {
    try {
        const response = await axios.get(`${appUrl}/proxyapp/get_suitetalk_token/`);
        console.log('SuiteTalk token response:', response.data);
        return response.data.token;
    } catch (error) {
        console.error('Error fetching SuiteTalk token:', error);
        return null;
    }
};

// Function to check sales order endpoint and retrieve tran_id
const checkSalesOrderEndpoint = async (targetEndpointID) => {
    try {
        console.log(`Checking sales order endpoint with targetEndpointID: ${targetEndpointID}`);
        const response = await axios.get(`${appUrl}/proxyapp/check-latest-sales-order/`, {
            params: { targetEndpointID }
        });
        console.log('Full response from check-latest-sales-order:', response.data);
        if (response.data && response.data.tran_id) {
            console.log(`TranId found: ${response.data.tran_id}`);
            return response.data.tran_id;
        } else {
            console.error('tran_id not found in response data');
            return null;
        }
    } catch (error) {
        console.error(`Error checking sales order endpoint:`, error.response ? error.response.data : error.message);
        return null;
    }
};

// New helper function to determine which template to use
const getTemplateConfig = (customerId) => {
    return templateConfigs[customerId] || templateConfigs.default;
};

// Read template file
const readTemplateFile = async (fileUrl) => {
    try {
        if (!fileUrl) {
            throw new Error("Template file URL is undefined or null.");
        }
        console.log(`Fetching template file from: ${fileUrl}`);
        const response = await fetch(fileUrl);
        if (!response.ok) {
            throw new Error(`Failed to fetch template file: ${response.statusText}`);
        }
        const blob = await response.blob();
        console.log('Blob size:', blob.size);
        const arrayBuffer = await blob.arrayBuffer();
        console.log('Template file arrayBuffer length:', arrayBuffer.byteLength);
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[1]];
        console.log('Worksheet content:', worksheet);
        return { workbook, worksheet };
    } catch (error) {
        console.error('Error reading template file:', error.message);
        throw error;
    }
};

// Convert to XLSX function using the template
const convertToXLSX = async (data, templateFileUrl, formatDataFn, tranId) => {
    try {
        console.log('Data received for conversion:', data);
        console.log('Template file URL:', templateFileUrl);

        const { workbook, worksheet } = await readTemplateFile(templateFileUrl);

        console.log('Worksheet before:', XLSX.utils.sheet_to_json(worksheet));

        // Preserve the existing content up to row 3
        const existingContent = {};
        Object.keys(worksheet).forEach(cell => {
            const match = cell.match(/^([A-Z]+)([1-3])$/);
            if (match) {
                existingContent[cell] = worksheet[cell];
            }
        });

        // Clear the worksheet
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = XLSX.utils.encode_cell({ c: C, r: R });
                delete worksheet[cell_address];
            }
        }

        // Restore the existing content
        Object.assign(worksheet, existingContent);

        const formattedData = data.map((item, index) => formatDataFn(item, index, tranId));

        // Write the formatted data starting from row 4
        formattedData.forEach((row, index) => {
            const rowIndex = index + 4;  // Start from row 4
            Object.entries(row).forEach(([col, value]) => {
                worksheet[`${col}${rowIndex}`] = { t: 's', v: value };
            });
        });

        // Update the worksheet range
        const newRange = XLSX.utils.decode_range(worksheet['!ref']);
        newRange.e.r = Math.max(newRange.e.r, 3 + formattedData.length);
        worksheet['!ref'] = XLSX.utils.encode_range(newRange);

        console.log('Worksheet after:', XLSX.utils.sheet_to_json(worksheet));

        const xlsxFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        console.log('XLSX content created using template');
        return xlsxFile;
    } catch (error) {
        console.error('Error converting to XLSX using template:', error.message);
        return null;
    }
};

// Main function to create XLSX from JSON data and handle upload
export const createXLSX = async (flatData, zeroAllPrices, authState, customer_id, targetEndpointID, well) => {
    console.log('zeroAllPrices parameter value:', zeroAllPrices);
    console.log('Customer ID:', customer_id); // Log the customer_id for debugging

    const uniqueId = generateUniqueId();

    let token = authState.token;
    if (!token) {
        console.log('Fetching new token...');
        token = await getSuiteTalkToken();
        if (!token) {
            console.error('Failed to retrieve token');
            return;
        }
    }

    // Check the sales order endpoint before proceeding
    const tranId = await checkSalesOrderEndpoint(targetEndpointID);
    console.log('TranId:', tranId);

    if (!tranId) {
        console.error('Failed to retrieve tranId');
        return;
    }

    // If zeroAllPrices is true, set all item prices to 0
    if (zeroAllPrices) {
        flatData.forEach(item => {
            item.item_price = 0;
        });
    }

    console.log('Flattened data for conversion:', flatData);

    // Get the appropriate template configuration based on the customer ID
    const templateConfig = getTemplateConfig(customer_id);
    const templateFileUrl = `${templateConfig.fileName}`;
    console.log('Template file URL:', templateFileUrl); // Log the template file URL for debugging

    const xlsxContent = await convertToXLSX(flatData, templateFileUrl, templateConfig.formatData, tranId);

    if (xlsxContent) {
        const fileName = `DWR_${flatData[0].well_name}_${uniqueId}.xlsx`;
        const blob = new Blob([xlsxContent], { type: 'application/octet-stream' });

        // Start the download process in the browser
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        console.log('XLSX file created and download initiated');

        // Prepare and upload the file to the "misc" category
        const formData = new FormData();
        const newFile = new File([blob], fileName, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        formData.append('file', newFile);
        console.log("Well ID " + well);
        formData.append('assigned_well', well);
        formData.append('doc_type', 'misc');

        try {
            const uploadToken = localStorage.getItem('my-jwt');
            await axios.post(`${appUrl}/jmrapp/upload/`, formData, {
                headers: {
                    'Authorization': `Bearer ${uploadToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('XLSX file uploaded successfully to "misc" category');
        } catch (error) {
            console.error('Error uploading XLSX file:', error);
        }
    }
};
