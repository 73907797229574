import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../styles/CreateContactForm.css';

const CreateContactForm = ({ onClose, onContactCreated }) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const token = localStorage.getItem('my-jwt');
        const response = await axios.get(`https://jmrservices.app/jmrapp/customers/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        setCustomers(response.data);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`https://jmrservices.app/jmrapp/create-contact/`, {
        firstname,
        lastname,
        email,
        phone,
        company: {
          id: companyId,
        },
      });

      if (response.status === 200 || response.status === 204) {
        confirmAlert({
          title: 'Success',
          message: 'Contact created successfully!',
          buttons: [
            {
              label: 'OK',
              onClick: () => {
                onClose();
                onContactCreated(response.data);
              },
            },
          ],
        });
      } else {
        throw new Error(response.data.message || 'Failed to create contact');
      }
    } catch (error) {
      console.error('Error creating contact:', error);
      confirmAlert({
        title: 'Error',
        message: 'Failed to create contact. Please try again.',
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });
    }
  };

  return (
    <div className="create-contact-form-container">
      <h2>Create New Contact</h2>
      <form onSubmit={handleSubmit} noValidate>
        <div className="form-row">
          <div className="form-col">
            <label className="form-label" htmlFor="firstname">First Name<span className="required">*</span></label>
            <input
              className="form-input"
              type="text"
              id="firstname"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
            />
          </div>
          <div className="form-col">
            <label className="form-label" htmlFor="lastname">Last Name<span className="required">*</span></label>
            <input
              className="form-input"
              type="text"
              id="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-col">
            <label className="form-label" htmlFor="email">Email<span className="required">*</span></label>
            <input
              className="form-input"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-col">
            <label className="form-label" htmlFor="phone">Phone</label>
            <input
              className="form-input"
              type="tel"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-col">
            <label className="form-label" htmlFor="company">Company<span className="required">*</span></label>
            <select
                className="form-input"
                id="company"
                value={companyId}
                onChange={(e) => setCompanyId(e.target.value)}
                required
            >
                <option value="">Select a company</option>
                {customers.sort((a, b) => a.name.localeCompare(b.name)).map((customer) => (
                    <option key={customer.id} value={customer.ns_iid}>
                        {customer.name}
                    </option>
                ))}
            </select>
          </div>
        </div>

        <div className="form-footer">
          <button className="submit-contact-button hover" type="submit">Create Contact</button>
          <button className="cancel-button hover" type="button" onClick={onClose}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default CreateContactForm;
