import React from 'react';
import { AuthProvider } from './api/AuthContext';
import { TimeRecordProvider } from './api/TimeRecordContext';


import Layout from './components/Layout';

function App() {
  return (
    <AuthProvider>
      <TimeRecordProvider> {/* Wrap Layout with TimeRecordProvider */}
        <Layout />
      </TimeRecordProvider>
    </AuthProvider>
  );
}

export default App;
