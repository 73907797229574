// WorkAssignment.jsx
import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import WorkDescriptionsModal from './WorkDescriptionsModal'; // Import the modal component
import '../styles/WorkAssignment.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const WorkAssignment = ({workAssignments, setWorkAssignments, workDescriptions, onDeleteAssignment, disabled}) => {
    // console.log(workDescriptions); // Add this in each component

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDuplicateError, setShowDuplicateError] = useState(false); // State for tracking duplicate attempts

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setShowDuplicateError(false); // Reset the duplicate error state whenever the modal is opened
};

const handleCloseModal = () => setIsModalOpen(false);

  // console.log('WORK DESCRIPTION ASSIGNMENTS: ',workDescAssignments)

  const handleWorkDescriptionSelect = (selectedIds) => {
    if (!Array.isArray(workDescriptions) || workDescriptions.length === 0) {
        console.error("workDescriptions is undefined, not an array, or empty");
        return;
    }

    let isDuplicate = false; // Flag to track if any duplicates were detected
    const updatedAssignments = [...workAssignments];

    selectedIds.forEach(id => {
      if (!updatedAssignments.some(assignment => assignment.workDescriptionId === id)) {
        const descriptionObj = workDescriptions.find(desc => desc.id === id);
        if (descriptionObj) {
          updatedAssignments.push({
            description: descriptionObj.description, // Store only the description name
            fromTime: '06:00',
            toTime: '06:30'
          });
        } else {
          console.error(`Description with id ${id} not found.`);
        }
      } else {
        isDuplicate = true;
      }
    });

    if (isDuplicate) {
        setShowDuplicateError(true); // Show the duplicate error message
    } else {
        setShowDuplicateError(false); // Reset the error state if no duplicates were added
        setWorkAssignments(updatedAssignments);
        handleCloseModal();
    }
};

  
  
  const handleTimeChange = (index, field, value) => {
    // Convert value to a Date object
    const selectedTime = new Date(`1970-01-01T${value}Z`);
    
    // Extract hours and minutes
    let hours = selectedTime.getUTCHours();
    let minutes = selectedTime.getUTCMinutes();
  
    // Round to the nearest 30 min
    if (minutes < 15) {
      minutes = 0; // Round down to :00
    } else if (minutes >= 15 && minutes < 45) {
      minutes = 30; // Round to :30
    } else {
      minutes = 0; // Round up to :00
      hours = (hours + 1) % 24; // Increment hour, reset to 0 if it was 23
    }
  
    // Construct the new time string
    const newTimeValue = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  
    // Update the state with the rounded time value
    const updatedAssignments = [...workAssignments];
    updatedAssignments[index][field] = newTimeValue;
    setWorkAssignments(updatedAssignments);
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Submit workAssignments to the backend
    // console.log("Submitting work assignments:", workAssignments);

  };

  const handleWorkDescriptionChange = (index, value) => {
    const updatedAssignments = [...workAssignments];
    updatedAssignments[index].description = value;
    setWorkAssignments(updatedAssignments);
  };

    useEffect(() => {
      const saveAssignmentsIfNeeded = async () => {
        // Check if any assignment has both fromTime and toTime set
        const readyToSaveAssignments = workAssignments.filter(assignment => 
          assignment.fromTime && assignment.toTime
        );
  
        if (readyToSaveAssignments.length > 0) {
          // console.log("Saving assignments:", readyToSaveAssignments);
          // Here, implement your save logic, e.g., an API call to save readyToSaveAssignments
        }
      };
  
      saveAssignmentsIfNeeded();
    }, [workAssignments]); // Depend on workAssignments so it runs whenever workAssignments change
  
    const handleDeleteClick = (assignmentId, index) => {
      if (assignmentId) {
        confirmAlert({
          title: 'Confirm Deletion',
          message: 'Are you sure you want to delete this work assignment?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => onDeleteAssignment(assignmentId),
            },
            {
              label: 'No',
              onClick: () => {},
            },
          ],
        });
      } else {
        setWorkAssignments(prevAssignments => prevAssignments.filter((_, i) => i !== index));
      }
    };

  return (
    <>
            <button className='btn hover' onClick={handleOpenModal} disabled={disabled}>Choose Work Descriptions</button>
            {showDuplicateError && <div style={{ color: 'red', marginTop: '10px' }}>Duplicate work descriptions not allowed at this time.</div>}
            <WorkDescriptionsModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                workDescriptions={workDescriptions}
                setSelectedWorkDescriptions={handleWorkDescriptionSelect}
            />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="work-description-cell">Work Description</TableCell>
            <TableCell className="from-time-cell">From Time</TableCell>
            <TableCell className="to-time-cell">To Time</TableCell>
            <TableCell className="delete-cell">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workAssignments.map((assignment, index) => (
            <TableRow key={index}>
              <TableCell className="work-description-cell">
                <TextField
                  value={assignment.description}
                  onChange={(e) => handleWorkDescriptionChange(index, e.target.value)}
                  multiline
                  fullWidth
                  disabled={disabled}
                />
              </TableCell>
              <TableCell className="from-time-cell">
                <TextField
                  type="time"
                  inputProps={{ step: "1800" }}
                  value={assignment.fromTime}
                  onChange={(e) => handleTimeChange(index, 'fromTime', e.target.value)}
                  placeholder="HH:MM"
                  fullWidth
                  disabled={disabled}
                />
              </TableCell>
              <TableCell className="to-time-cell">
                <TextField
                  type="time"
                  inputProps={{ step: "1800" }}
                  value={assignment.toTime}
                  onChange={(e) => handleTimeChange(index, 'toTime', e.target.value)}
                  fullWidth
                  disabled={disabled}
                />
              </TableCell>
              <TableCell className="delete-cell">
                <button onClick={() => handleDeleteClick(assignment.id, index)} disabled={disabled}>Delete</button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
        {/* <Button type="submit" variant="contained">Save Work Assignments</Button> */}
    </>
  );
};

export default WorkAssignment;
