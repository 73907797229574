import React, { createContext, useContext, useReducer } from 'react';

const TimeRecordContext = createContext();

const initialState = {
  timeRecords: [],
};

function timeRecordReducer(state, action) {
  switch (action.type) {
    case 'SET_TIME_RECORDS':
      return { ...state, timeRecords: action.payload };
    case 'ADD_TIME_RECORD':
      return { ...state, timeRecords: [...state.timeRecords, action.payload] };
    case 'UPDATE_TIME_RECORD':
      return {
        ...state,
        timeRecords: state.timeRecords.map(record =>
          record.id === action.payload.id ? action.payload : record),
      };
    case 'REMOVE_TIME_RECORD':
      return {
        ...state,
        timeRecords: state.timeRecords.filter(record => record.id !== action.payload),
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export function TimeRecordProvider({ children }) {
  const [state, dispatch] = useReducer(timeRecordReducer, initialState);

  return (
    <TimeRecordContext.Provider value={{ state, dispatch }}>
      {children}
    </TimeRecordContext.Provider>
  );
}

export function useTimeRecord() {
  const context = useContext(TimeRecordContext);
  if (context === undefined) {
    throw new Error('useTimeRecord must be used within a TimeRecordProvider');
  }
  return context;
}
