import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useAuth } from '../api/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

import '../styles/WellRows.css';
import DWRForm from './DWRForm';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SendIcon from '@mui/icons-material/Send';
import { newExportObjectAsPDF } from "../util/NewExportPdf";
import { Checkbox, Button } from '@mui/material';

const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
  throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const DWRListView = () => {
  const { authState } = useAuth();
  const [dwrs, setDwrs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterModel, setFilterModel] = useState(() => {
    const savedFilter = localStorage.getItem('dwrListViewFilter');
    return savedFilter ? JSON.parse(savedFilter) : { items: [] };
  });
  const [selectedDwr, setSelectedDwr] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false); // For toggling delete mode
  const [selectedForDeletion, setSelectedForDeletion] = useState([]); // To track selected DWRs for deletion
  const token = authState?.token;
  const currentUser = authState?.user;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('dwrListViewFilter', JSON.stringify(filterModel));
  }, [filterModel]);

  const fetchDWRs = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${appUrl}/jmrapp/dailyworkrecords/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDwrs(response.data);
    } catch (error) {
      console.error("Failed to fetch DWRs - Please log out and log back in", error);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (authState.authenticated) {
      fetchDWRs();
    }
  }, [authState.authenticated, fetchDWRs]);

  const handleDwrClick = async (dwr) => {
    if (isDeleteMode) return; // Disable row click when in delete mode
    try {
      const token = localStorage.getItem('my-jwt');
      const dwrResponse = await axios.get(`${appUrl}/jmrapp/dailyworkrecords/${dwr.id}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const wellId = dwrResponse.data.well.id;
      const wellResponse = await axios.get(`${appUrl}/jmrapp/wells/${wellId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const combinedData = {
        ...dwrResponse.data,
        well: wellResponse.data
      };

      setSelectedDwr(combinedData);
      navigate('.', { state: { ...location.state, well: wellResponse.data }, replace: true });
      setShowForm(true);
    } catch (error) {
      console.error('Failed to fetch full DWR data:', error);
      confirmAlert({
        title: "Error",
        message: "Failed to load DWR data. Please try again.",
        buttons: [{ label: "OK" }],
      });
    }
  };

  const handleCloseForm = () => {
    setSelectedDwr(null);
    setShowForm(false);
    fetchDWRs(); // Refresh the DWR list when the form is closed
  };

  const handlePDFDownload = async (dwr, event) => {
    event.stopPropagation();
    try {
      const fullDwrData = await fetchFullDWRData(dwr.id);
      await newExportObjectAsPDF(fullDwrData, token);
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      confirmAlert({
        title: "Error",
        message: "Failed to generate PDF. Please try again.",
        buttons: [{ label: "OK" }],
      });
    }
  };

  const handleApprovalClick = async (dwr, event) => {
    event.stopPropagation();
    try {
      const fullDwrData = await fetchFullDWRData(dwr.id);
      const pdfBlob = await newExportObjectAsPDF(fullDwrData, token, false);
      const pdfFile = new Blob([pdfBlob], { type: 'application/pdf' });
      const formData = new FormData();
      formData.append('pdf', pdfFile, 'dwr.pdf');

      if (currentUser && (currentUser.is_manager || currentUser.is_superuser)) {
        await axios.post(`${appUrl}/jmrapp/dailyworkrecords/${dwr.id}/send_customer_approval_email/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log('Approval request sent to customer successfully');

        await axios.post(`${appUrl}/jmrapp/dailyworkrecords/${dwr.id}/send_reports_email/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log('Reports email sent successfully');
      } else {
        await axios.post(`${appUrl}/jmrapp/dailyworkrecords/${dwr.id}/send_manager_approval_email/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log('Approval request sent to manager successfully');
      }
      
      fetchDWRs(); // Refresh the DWR list after sending the approval
    } catch (error) {
      console.error('Failed to send approval request:', error);
    }
  };

  const fetchFullDWRData = async (dwrId) => {
    try {
      const response = await axios.get(`${appUrl}/jmrapp/dailyworkrecords/${dwrId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch full DWR data for ID ${dwrId}:`, error);
      throw error;
    }
  };

  const handleDeleteToggle = () => {
    setIsDeleteMode(!isDeleteMode);
    setSelectedForDeletion([]); // Reset selected DWRs when toggling
  };

  const handleCancelDelete = () => {
    setIsDeleteMode(false);
    setSelectedForDeletion([]); // Clear selection when canceling
  };

  const handleCheckboxChange = (dwrId) => {
    setSelectedForDeletion((prevSelected) =>
      prevSelected.includes(dwrId)
        ? prevSelected.filter((id) => id !== dwrId)
        : [...prevSelected, dwrId]
    );
  };

  const handleDeleteSelectedDWRs = async () => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete the selected DWRs?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await Promise.all(
                selectedForDeletion.map((dwrId) =>
                  axios.delete(`${appUrl}/jmrapp/dailyworkrecords/${dwrId}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                  })
                )
              );
              fetchDWRs(); // Refresh the list after deletion
              setIsDeleteMode(false);
              setSelectedForDeletion([]);
            } catch (error) {
              console.error('Failed to delete selected DWRs:', error);
            }
          },
        },
        { label: "No" },
      ],
    });
  };

  const columns = [
    { field: 'date', headerName: 'Date', flex: 1, filterable: true },
    { field: 'well_name', headerName: 'Well', flex: 1, filterable: true },
    { field: 'rig_name', headerName: 'Rig', flex: 1, filterable: true },
    { 
      field: 'superintendent', 
      headerName: 'Superintendent', 
      flex: 1,
      valueGetter: (params) => 
        params.row.primary_manager 
          ? `${params.row.primary_manager.first_name} ${params.row.primary_manager.last_name}`
          : 'N/A'
    },
    {
      field: 'created_by',
      headerName: 'Supervisor',
      flex: 1,
      valueGetter: (params) =>
        params.row.created_by
          ? `${params.row.created_by.first_name} ${params.row.created_by.last_name}`
          : 'N/A'
    },
    { 
      field: 'is_last_day', 
      headerName: 'Last Day', 
      flex: 1,
      renderCell: (params) => (
        <Checkbox checked={params.value} disabled />
      ),
    },
    { 
      field: 'notes', 
      headerName: 'Notes', 
      flex: 1,
      renderCell: (params) => (
        <div className="notes-cell">
          {params.value}
        </div>
      ),
    },
    {
      field: 'approval',
      headerName: 'Approval',
      flex: 1,
      renderCell: (params) => (
        params.row.status === 'In Progress' && (
          <button onClick={(event) => handleApprovalClick(params.row, event)}>
            <SendIcon />
          </button>
        )
      ),
    },
    {
      field: 'pdf',
      headerName: 'PDF',
      flex: 1,
      renderCell: (params) => (
        <button onClick={(event) => handlePDFDownload(params.row, event)}>
          <FileDownloadIcon />
        </button>
      ),
    },
    ...(isDeleteMode
      ? [
          {
            field: 'delete',
            headerName: 'Delete?',
            flex: 1,
            renderCell: (params) => (
              <Checkbox
                checked={selectedForDeletion.includes(params.row.id)}
                onChange={() => handleCheckboxChange(params.row.id)}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <div style={{ height: '75vh', width: '100%' }}>
      {isDeleteMode ? (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="contained"
            onClick={handleDeleteSelectedDWRs}
            sx={{
              backgroundColor: '#0b6e4f',
              '&:hover': {
                backgroundColor: '#11372b',
              },
              textTransform: 'none', // This ensures the text stays in normal case
            }}
          >
            Delete Selected DWRs
          </Button>
          <Button
            variant="outlined"
            onClick={handleCancelDelete}
            sx={{
              color: '#0b6e4f',
              borderColor: '#0b6e4f',
              '&:hover': {
                backgroundColor: '#11372b',
                color: '#ffffff',
                borderColor: '#11372b',
              },
              textTransform: 'none', // This ensures the text stays in normal case
            }}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <Button
          variant="contained"
          onClick={handleDeleteToggle}
          sx={{
            backgroundColor: '#0b6e4f',
            '&:hover': {
              backgroundColor: '#11372b',
            },
            textTransform: 'none', // This ensures the text stays in normal case
          }}
        >
          Select DWRs for Deletion
        </Button>
      )}

      {showForm ? (
        <div>
          <DWRForm
            selectedDwr={selectedDwr}
            setSelectedDwr={setSelectedDwr}
            onClose={handleCloseForm}
            setShowForm={setShowForm}
            onSubmit={handleCloseForm}
          />
          <button className="btn close-btn" onClick={handleCloseForm}>
            Close Form
          </button>
        </div>
      ) : (
        <DataGrid
          rows={dwrs}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5, page: 0 },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          filterModel={filterModel}
          onFilterModelChange={(model) => setFilterModel(model)}
          loading={isLoading}
          onRowClick={(params) => handleDwrClick(params.row)}
          getRowHeight={() => 'auto'}
          getRowClassName={(params) => params.row.is_last_day ? 'last-day-row' : ''}
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
              lineHeight: 'normal',
              padding: '8px',
            },
            '& .MuiDataGrid-row': {
              maxHeight: 'none !important',
            },
            '& .last-day-row': {
              backgroundColor: 'rgba(255, 253, 231, 1)', // Very light yellow
              '&:hover': {
                backgroundColor: 'rgba(255, 253, 231, 0.7)',
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default DWRListView;
