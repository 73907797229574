export const convertTo12Hour = (timeString) => {
  
    if (!timeString) {
      return { hour: '12', minute: '00', meridian: 'AM' };
    }
  
    const [hours, minutes] = timeString.split(':');
    let hour = parseInt(hours, 10);
    const minute = minutes;
    const meridian = hour >= 12 ? 'PM' : 'AM';
  
    if (hour === 0) {
      hour = 12;
    } else if (hour > 12) {
      hour -= 12;
    }
  
  
    return {
      hour: hour.toString(),
      minute,
      meridian,
    };
  };