import React, { useState } from 'react';
import { useAuth } from "../api/AuthContext";
import { 
  Typography, 
  Paper, 
  Button, 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  Divider,
  CircularProgress
} from '@mui/material';
import axios from 'axios';

const appUrl = process.env.REACT_APP_APP_URL;
if (!appUrl) {
  throw new Error(
    "REACT_APP_APP_URL is not defined. Please set it in your .env file."
  );
}

const AccountPage = () => {
  const { authState, onLogout } = useAuth();
  const [adminUrl, setAdminUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGetAdminAccess = async () => {
    setLoading(true);
    try {
      const response = await  axios.get(`${appUrl}/admin/`, {
        headers: { Authorization: `Bearer ${authState.token}` }
      });
    } catch (error) {
      console.error('Error fetching admin URL:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Account Information
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Email" secondary={authState.user?.email} />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Role" secondary={
              authState.user?.is_superuser ? "Superuser" :
              authState.user?.is_manager ? "Manager" :
              authState.user?.is_staff ? "Staff" : "Regular User"
            } />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="Assigned Rig" secondary={authState.user?.assigned_rig || "None"} />
          </ListItem>
        </List>
        
        {authState.user?.is_superuser && (
          <Box mt={2}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleGetAdminAccess}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Access Admin Page"}
            </Button>
            {adminUrl && (
              <Box mt={2}>
                <Typography variant="body1">
                  Admin access granted. Click{' '}
                  <a href={`${appUrl}/admin/`} target="_blank" rel="noopener noreferrer">
                    here
                  </a> to access the admin page.
                </Typography>
              </Box>
            )}
          </Box>
        )}
        
        <Box mt={3}>
          <Button variant="outlined" color="secondary" onClick={onLogout}>
            Logout
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AccountPage;
