import React from 'react';
import PropTypes from 'prop-types';

const TaxAssignment = ({ dwr, items }) => {
  const normalizeState = (state) => {
    const stateMap = {
      'AL': 'ALABAMA', 'AK': 'ALASKA', 'AZ': 'ARIZONA', 'AR': 'ARKANSAS', 'CA': 'CALIFORNIA',
      'CO': 'COLORADO', 'CT': 'CONNECTICUT', 'DE': 'DELAWARE', 'FL': 'FLORIDA', 'GA': 'GEORGIA',
      'HI': 'HAWAII', 'ID': 'IDAHO', 'IL': 'ILLINOIS', 'IN': 'INDIANA', 'IA': 'IOWA',
      'KS': 'KANSAS', 'KY': 'KENTUCKY', 'LA': 'LOUISIANA', 'ME': 'MAINE', 'MD': 'MARYLAND',
      'MA': 'MASSACHUSETTS', 'MI': 'MICHIGAN', 'MN': 'MINNESOTA', 'MS': 'MISSISSIPPI', 'MO': 'MISSOURI',
      'MT': 'MONTANA', 'NE': 'NEBRASKA', 'NV': 'NEVADA', 'NH': 'NEW HAMPSHIRE', 'NJ': 'NEW JERSEY',
      'NM': 'NEW MEXICO', 'NY': 'NEW YORK', 'NC': 'NORTH CAROLINA', 'ND': 'NORTH DAKOTA', 'OH': 'OHIO',
      'OK': 'OKLAHOMA', 'OR': 'OREGON', 'PA': 'PENNSYLVANIA', 'RI': 'RHODE ISLAND', 'SC': 'SOUTH CAROLINA',
      'SD': 'SOUTH DAKOTA', 'TN': 'TENNESSEE', 'TX': 'TEXAS', 'UT': 'UTAH', 'VT': 'VERMONT',
      'VA': 'VIRGINIA', 'WA': 'WASHINGTON', 'WV': 'WEST VIRGINIA', 'WI': 'WISCONSIN', 'WY': 'WYOMING'
    };

    const uppercaseState = state.toUpperCase();
    return stateMap[uppercaseState] || uppercaseState;
  };

  const assignTaxAttributes = (item, state) => {
    console.log(`Processing taxes for item with NetSuite ID: ${item.item_netsuite_id}`);

    const isTurnkey = item.item_turnkey === true || item.item_turnkey === 'true';
    const cementNetSuiteIds = ["2369", "109", "1941"];
    const isCementByNetSuiteId = cementNetSuiteIds.includes(item.item_netsuite_id);

    console.log(`Item details:
      - Name: ${item.item_name}
      - Turnkey: ${isTurnkey}
      - State: ${state}
      - Is Cement (by NetSuite ID): ${isCementByNetSuiteId}
      - NetSuite ID: ${item.item_netsuite_id}`);

    let taxItem = 'No';
    let taxable = 'No';

    if (!isTurnkey) {
      taxable = 'Yes';
      console.log('Item is not turnkey, setting taxable to Yes');

      switch (state) {
        case 'COLORADO':
          console.log('Applying Colorado tax rules');
          if (isCementByNetSuiteId) {
            taxItem = 'Yes';
            console.log('Item is cement (matched by NetSuite ID), setting taxItem to Yes');
          } else {
            console.log('Item does not meet Colorado tax criteria, taxItem remains No');
          }
          break;
        case 'NEW MEXICO':
          console.log('Applying New Mexico tax rules');
          taxItem = 'Yes';
          console.log('All non-turnkey items are taxItems in New Mexico');
          break;
        case 'TEXAS':
          console.log('Applying Texas tax rules');
          console.log('All items are non-taxable in Texas');
          break;
        default:
          console.warn(`Unknown state: ${state}. Defaulting to non-taxable.`);
      }
    } else {
      console.log('Item is turnkey, remaining non-taxable');
    }

    console.log(`Final tax assignment for item with NetSuite ID ${item.item_netsuite_id}:
      - Tax Item: ${taxItem}
      - Taxable: ${taxable}`);

    return { ...item, taxItem, taxable };
  };

  console.log('Starting tax assignment process');
  console.log(`Total items to process: ${items.length}`);

  const state = normalizeState(dwr?.well?.state || '');
  const assignedItems = items.map(item => assignTaxAttributes(item, state));
  
  console.log('Tax assignment process completed');
  console.log(`Total items processed: ${assignedItems.length}`);
  console.log('Assigned items:', JSON.stringify(assignedItems, null, 2));

  return assignedItems;
};

TaxAssignment.propTypes = {
  dwr: PropTypes.shape({
    well: PropTypes.shape({
      state: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    item_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    item_name: PropTypes.string.isRequired,
    item_netsuite_id: PropTypes.string.isRequired,
    item_turnkey: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  })).isRequired,
};

export default TaxAssignment;
