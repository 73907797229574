import React, { useState, useEffect } from 'react';
import '../styles/WellForm.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Typography, Alert } from '@mui/material';


// Set up an Axios interceptor to log all response headers
axios.interceptors.response.use(
  (response) => {
    console.log('Response Headers:', response.headers);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const CreateBatteryWellForm = ({ well, onClose }) => {
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [county, setCounty] = useState('');
  const [state, setState] = useState('');
  const [jobNumber, setJobNumber] = useState('');
  const [rigNo, setRigNo] = useState('');
  const [area, setArea] = useState('');
  const [customerRepresentative, setCustomerRepresentative] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [api, setAPI] = useState('xx-xxx-xxxxx'); // Default value for the API field
  const [dwrDate, setDWRDate] = useState('');
  const [status, setStatus] = useState('In Progress'); // Set default to "In Progress"
  const [wellName, setWellName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [rigs, setRigs] = useState([]);
  const [group, setGroup] = useState('');
  const [afePo, setAfePo] = useState('');
  const [selectedRigNetsuiteId, setSelectedRigNetsuiteId] = useState('');
  const [selectedCustomerNetsuiteId, setSelectedCustomerNetsuiteId] = useState('');

  const navigate = useNavigate();

  // Get App URL
  const appUrl = process.env.REACT_APP_APP_URL;

  if (!appUrl) {
    throw new Error('REACT_APP_APP_URL is not defined. Please set it in your .env file.');
  }

  console.log('RIG DATA :', rigs);
  console.log('CUSTOMER DATA :', customers);

  useEffect(() => {
    const fetchCustomersAndRigs = async () => {
      try {
        const token = localStorage.getItem('my-jwt');
        const [customersResponse, rigsResponse] = await Promise.all([
          axios.get(`${appUrl}/jmrapp/customers/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${appUrl}/jmrapp/rigs/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        setCustomers(customersResponse.data);
        setRigs(rigsResponse.data);
      } catch (error) {
        console.error('Error fetching customers and rigs:', error);
      }
    };

    fetchCustomersAndRigs();
  }, [appUrl]);

  const handleRigChange = (e) => {
    const selectedRigId = e.target.value;
    setRigNo(selectedRigId);

    // Find the selected rig and get its netsuite_id
    const selectedRig = rigs.find((rig) => rig.id.toString() === selectedRigId);
    if (selectedRig && selectedRig.netsuite_id) {
      setSelectedRigNetsuiteId(selectedRig.netsuite_id);
    } else {
      setSelectedRigNetsuiteId('');
      console.error('Selected rig does not have a netsuite_id');
    }
  };

  const handleCustomerChange = (e) => {
    const selectedCustomerId = e.target.value;
    setCustomerId(selectedCustomerId);

    // Find the selected customer and get its netsuite_id
    const selectedCustomer = customers.find((customer) => customer.id.toString() === selectedCustomerId);
    if (selectedCustomer && selectedCustomer.ns_iid) {
      setSelectedCustomerNetsuiteId(selectedCustomer.ns_iid);
      console.log('Selected customer NetSuite ID (ns_iid):', selectedCustomer.ns_iid);
    } else {
      setSelectedCustomerNetsuiteId('');
      console.error('Selected customer does not have a netsuite_id');
    }
  };

  const formatAPI = (value) => {
    // Remove any non-digit characters
    const digits = value.replace(/\D/g, '');

    // Format to "xx-xxx-xxxxx" with placeholders
    let formatted = 'xx-xxx-xxxxx';

    // Replace placeholder characters with user input
    for (let i = 0; i < digits.length && i < 10; i++) {
      formatted = formatted.replace('x', digits[i]);
    }

    return formatted;
  };

  const handleAPIChange = (e) => {
    const formattedAPI = formatAPI(e.target.value);
    setAPI(formattedAPI);
  };

  const handleCreateWell = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    console.log('Starting create process for well:', wellName);

    // Validate required fields
    if (!customerId || !rigNo || !area || !wellName || !api || !status) {
      alert('Please fill in all required fields: Customer, Rig, Area, Well Name, API, and Status.');
      setIsCreating(false);
      return;
    }

    // Additional validation: Ensure the API number is fully completed and does not contain "x"
    if (api.includes('x')) {
      alert('The API number is incomplete. Please ensure the API number is fully entered without any "x"s.');
      setIsCreating(false);
      return;
    }

    try {
      const token = localStorage.getItem('my-jwt');

      const netsuiteData = {
        companyName: wellName,
        projectExpenseType: -2,
        custentity_api_number: api,
        custentity_rig: selectedRigNetsuiteId, // Assuming rigNo is an integer value
        parent_netsuite_id: selectedCustomerNetsuiteId, // Use the NetSuite ID of the selected customer
      };

      // Create well in NetSuite
      const netsuiteResponse = await axios.post(`${appUrl}/proxyapp/proxy/`, netsuiteData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Logging the full response headers
      console.log('NetSuite data:', netsuiteResponse.netsuiteData);
      console.log('NetSuite response headers:', netsuiteResponse.headers);

      let netsuiteId;
      if (netsuiteResponse.data && netsuiteResponse.data.id) {
        netsuiteId = netsuiteResponse.data.id;
        console.log('NetSuite ID:', netsuiteId);
      } else if (netsuiteResponse.headers && netsuiteResponse.headers['location']) {
        // Extract the ID from the Location header if the ID is not in the response body
        const locationHeader = netsuiteResponse.headers['location'];
        netsuiteId = locationHeader.split('/').pop(); // Get the last part of the URL
        console.log('Extracted NetSuite ID from Location header:', netsuiteId);
      } else {
        throw new Error('ID not found in NetSuite response');
      }

      // Create well in local database
      const localDbData = {
        customer_name: customerName,
        customer_id: customerId,
        customer_address: customerAddress,
        assigned_rig_id: rigNo, // Add this line to include the assigned_rig_id
        county,
        state,
        job_number: jobNumber,
        area,
        customer_representative: customerRepresentative,
        latitude,
        longitude,
        api,
        rigNo,
        dwr_date: dwrDate,
        status,
        name: wellName, // Changed to 'name' to match the backend requirement
        netsuite_id: netsuiteId, // Set the NetSuite ID in the local database
      };

      console.log('Sending data to local database:', localDbData);

      const localDbResponse = await axios.post(`${appUrl}/jmrapp/wells/`, localDbData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Local DB response:', localDbResponse);

      if (localDbResponse.status === 201) {
        console.log('Well created in local database:', localDbResponse.data);
        alert('Well created successfully!');
        navigate('/'); // Redirect to the wells list or any other appropriate page
      } else {
        console.error('Unexpected response from local database:', localDbResponse);
        alert('Failed to create well in local database. Please try again.');
      }
    } catch (error) {
      console.error('Error creating well:', error.response ? error.response.data : error.message);
      alert('Failed to create well. Please try again.');
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <form className='form' onSubmit={handleCreateWell} noValidate>
      <div className='formRow'>
        <div className='formCol'>
          <label className='formLabel' htmlFor='customer'>
            Customer <span className='required'>*</span>
          </label>
          <select
            className='formInput'
            id='customer'
            value={customerId}
            onChange={handleCustomerChange}
            required
          >
            <option value=''>Select a customer</option>
            {customers.sort((a, b) => a.name.localeCompare(b.name)).map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.name}
              </option>
            ))}
          </select>
        </div>

        <div className='formCol'>
          <label className='formLabel' htmlFor='assignedRig'>
            Assigned Rig <span className='required'>*</span>
          </label>
          <select
            className='formInput'
            id='assignedRig'
            value={rigNo}
            onChange={handleRigChange}
            required
          >
            <option value=''>Select a rig</option>
            {rigs.sort((a, b) => a.name.localeCompare(b.name)).map((rig) => (
              <option key={rig.id} value={rig.id}>
                {rig.name}
              </option>
            ))}
          </select>
        </div>

        <div className='formCol'>
          <label className='formLabel5' htmlFor='area'>
            Area Category <span className='required'>*</span>
          </label>
          <span className='formSpan'>
            <select
              className='formInput5'
              id='area'
              value={area}
              onChange={(e) => setArea(e.target.value)}
              required
            >
              <option value=''>Select an area</option>
              <option value='Deleware Basin'>Deleware Basin</option>
              <option value='DJ Basin'>DJ Basin</option>
              <option value='Permian'>Permian</option>
              <option value='San Juan Basin'>San Juan Basin</option>
              <option value='Reclamation'>Reclamation</option>
            </select>
          </span>
        </div>
        <div className='formCol3'>
          <label className='formLabel2' htmlFor='wellName'>
            Well Name <span className='required'>*</span>
          </label>
          <input
            className='formInput2'
            type='text'
            id='wellName'
            value={wellName}
            onChange={(e) => setWellName(e.target.value)}
            required
          />
        </div>
      </div>

      <div className='formRow2'>
        <div className='formCol3'>
          <label className='formLabel3' htmlFor='afePo'>
            AFE/PO No.
          </label>
          <input
            className='formInput3'
            type='text'
            id='afePo'
            value={afePo}
            onChange={(e) => setAfePo(e.target.value)}
          />
        </div>
        <div className='formCol3'>
          <label className='formLabel2' htmlFor='api'>
            API Number <span className='required'>*</span>
          </label>
          <input
            className='formInput2'
            type='text'
            id='api'
            value={api}
            onChange={handleAPIChange}
            required
          />
        </div>
        <div className='formCol3'>
          <label className='formLabel3' htmlFor='jobNumber'>
            Job Number
          </label>
          <input
            className='formInput3'
            type='text'
            id='jobNumber'
            value={jobNumber}
            onChange={(e) => setJobNumber(e.target.value)}
          />
        </div>
        <div className='formCol3'>
          <label className='formLabel3' htmlFor='customerRepresentative'>
            Customer Representative
          </label>
          <input
            className='formInput3'
            type='text'
            id='customerRepresentative'
            value={customerRepresentative}
            onChange={(e) => setCustomerRepresentative(e.target.value)}
          />
        </div>
      </div>

      <div className='formRow3'>
        <div className='formCol3'>
          <label className='formLabel3' htmlFor='county'>
            County
          </label>
          <input
            className='formInput3'
            type='text'
            id='county'
            value={county}
            onChange={(e) => setCounty(e.target.value)}
          />
        </div>
        <div className='formCol3'>
          <label className='formLabel3' htmlFor='state'>
            State
          </label>
          <input
            className='formInput3'
            type='text'
            id='state'
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </div>
        <div className='formCol3'>
          <label className='formLabel' htmlFor='latitude'>
            Latitude
          </label>
          <input
            className='formInput'
            type='number'
            id='latitude'
            value={latitude}
            onChange={(e) => setLatitude(e.target.value.toString())}
          />
          <Alert severity="warning" sx={{ mt: 1, py: 0 }}>
            <Typography variant="caption">
              Latitude value must not exceed 6 decimal places (Example: 123.456789)
            </Typography>
          </Alert>
        </div>
        <div className='formCol3'>
          <label className='formLabel' htmlFor='longitude'>
            Longitude
          </label>
          <input
            className='formInput'
            type='number'
            id='longitude'
            value={longitude}
            onChange={(e) => setLongitude(e.target.value)}
          />
          <Alert severity="warning" sx={{ mt: 1, py: 0 }}>
            <Typography variant="caption">
            Longitude value must not exceed 6 decimal places (Example: 123.456789)
            </Typography>
          </Alert>
        </div>
      </div>

      <div className='formRow5'>
        <div>
          <label className='formLabel' htmlFor='wellStatus'>
            Well Status <span className='required'>*</span>
          </label>
          <select
            className='formInput'
            id='wellStatus'
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option value=''>Select a status</option>
            <option value='Awarded'>Awarded</option>
            <option value='Closed'>Closed</option>
            <option value='In Progress'>In Progress</option>
          </select>
        </div>
      </div>

      <div className='formFooter'>
        <button type='submit' disabled={isCreating}>
          {isCreating ? 'Creating...' : 'Create Well'}
        </button>
        <button className='cancel-button hover' type='button' onClick={onClose}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default CreateBatteryWellForm;
