import React, { useState } from 'react';
import axios from 'axios';
import { Button, Typography, Box, Stack } from '@mui/material';
import CreateUserForm from './CreateUserForm';

// Get App Url
const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const SettingsPage = () => {
  // State to track sync status
  const [isSyncing, setIsSyncing] = useState(false);
  const [isCreateUserOpen, setIsCreateUserOpen] = useState(false);

  // Function to trigger the sync process
  const syncContacts = async () => {
    setIsSyncing(true); // Set sync state to true when the sync starts
    try {
      const response = await axios.get(`${appUrl}/jmrapp/sync-netsuite-contacts/`);
      alert(response.data.message);
    } catch (error) {
      // Check for the specific 429 error code and respond accordingly
      if (error.response?.status === 429) {
        alert('Contact sync already in progress, unable to start another. Please try again in a few minutes.');
      } else {
        console.error('Error syncing contacts:', error.response?.data?.error || error.message);
        alert('Failed to sync contacts. Please check the console for more details.');
      }
    } finally {
      setIsSyncing(false); // Set sync state to false when the sync ends
    }
  };

  const syncEmployees = async () => {
    setIsSyncing(true); // Set sync state to true when the sync starts
    try {
      const response = await axios.get(`${appUrl}/jmrapp/sync-netsuite-employees/`);
      alert(response.data.message);
    } catch (error) {
      // Check for the specific 429 error code and respond accordingly
      if (error.response?.status === 429) {
        alert('Employee sync already in progress, unable to start another. Please try again in a few minutes.');
      } else {
        console.error('Error syncing employees:', error.response?.data?.error || error.message);
        alert('Failed to sync employees. Please check the console for more details.');
      }
    } finally {
      setIsSyncing(false); // Set sync state to false when the sync ends
    }
  };

  const syncCustomers = async () => {
    setIsSyncing(true); // Set sync state to true when the sync starts
    try {
      const response = await axios.get(`${appUrl}/jmrapp/sync-netsuite-customers/`);
      alert(response.data.message);
    } catch (error) {
      // Check for the specific 429 error code and respond accordingly
      if (error.response?.status === 429) {
        alert('Customer sync already in progress, unable to start another. Please try again in a few minutes.');
      } else {
        console.error('Error syncing customers:', error.response?.data?.error || error.message);
        alert('Failed to sync customers. Please check the console for more details.');
      }
    } finally {
      setIsSyncing(false); // Set sync state to false when the sync ends
    }
  };


 return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>

      <Stack spacing={3}>
        <Box>
          <Typography variant="h6" gutterBottom>
            User Management
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsCreateUserOpen(true)}
          >
            Create New User
          </Button>
        </Box>

        <Box>
          <Typography variant="h6" gutterBottom>
            NetSuite Synchronization
          </Typography>
          <Stack direction="column" spacing={2}>
            <Button
              variant="contained"
              onClick={syncContacts}
              disabled={isSyncing}
            >
              {isSyncing ? 'Syncing...' : 'Sync Netsuite Contacts'}
            </Button>

            <Button variant="contained" onClick={syncEmployees} disabled={isSyncing}>
              {isSyncing ? 'Syncing...' : 'Sync Netsuite Employees'}
            </Button>
            <Button variant="contained" onClick={syncCustomers} disabled={isSyncing} >
              {isSyncing ? 'Syncing...' : 'Sync Netsuite Customers'}
            </Button>
          </Stack>
        </Box>
      </Stack>

      <CreateUserForm
        open={isCreateUserOpen}
        onClose={() => setIsCreateUserOpen(false)}
      />
    </Box>
  );
};

export default SettingsPage;
