import * as React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../api/AuthContext';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../assets/JMR-Logo.png'

type SettingOption = 'Account' | 'Settings' | 'Logout';
const settings: SettingOption[] = ['Account', 'Settings', 'Logout'];

type User = {
  username: string;
  // Add other user properties here if needed
};

// Define the AuthState type based on the information you provided
type AuthState = {
  token: string | null;
  authenticated: boolean | null;
  user?: User;
};


function ResponsiveAppBar() {
  
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  
  const navigate = useNavigate(); // Initialize the navigate function
  const { onLogout, authState } = useAuth();

  
  const handleLogout = async() => {
    if (onLogout) { // Check if onLogout is defined
      await onLogout(); // If defined, call it
      navigate('/login');
    } else {
      console.error('Logout function is undefined');
    }
  };
  // // Function to get user initials
  // const getUserInitials = React.useCallback((): string => {
  //   if (authState?.user?.username) {
  //     const names = authState.user.username.split('.');
  //     if (names.length >= 2) {
  //       return `${names[0][0]}${names[1][0]}`.toUpperCase();
  //     }
  //     return authState.user.username.substring(0, 2).toUpperCase();
  //   }
  //   return '';
  // }, [authState]);


  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = (setting: SettingOption) => {
    if (setting === 'Logout') {
        handleLogout();
    } else if (setting === 'Account') {
        navigate('/account'); // Adjust the route as necessary
    } else if (setting === 'Settings') {
        navigate('/settings'); // Adjust the route as necessary
    }
    handleCloseUserMenu(); // Close the user menu for all options
  };

  const handleLogoClick = () => {
    // Force a fresh load of the ListView
    window.location.href = '/';
  };




  



  return (
        <AppBar position="static" sx={{ backgroundColor: '#0b6e4f' }}>
          <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
          {/* Logo aligned to the left */}
          <Box sx={{ mr: 2, display: 'flex', alignItems: 'left', cursor: 'pointer' }} onClick={handleLogoClick}>
            <img src={logo} alt="Logo" style={{ height: '50px' }} />
          </Box>

          {/* Slogan centered */}
          <Typography
            variant="h6"
            noWrap
            sx={{
              flexGrow: 1,
              fontStyle: 'italic',
              textAlign: 'center',
              color: 'inherit',
              display: { xs: 'flex', md: 'flex' }, // Ensure visibility across all screen sizes
              justifyContent: 'center', // Center the slogan text
            }}
          >
            Quality, Safety, Service
          </Typography>

          {/* Avatar and settings on the right */}
          <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar src="/broken-image.jpg" />            </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleMenuClick(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
