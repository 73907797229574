import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import ListView from './ListView';
import SettingsPage from './UserSettings';
import UserAccount from './UserAccount';
import Well from './Well';
import DWR from './DWR';
import { useAuth } from '../api/AuthContext'
import ResponsiveAppBar from './ResponsiveAppBar';


const Layout = () => {
    const { authState } = useAuth();

    return (
      <>
        {authState.authenticated && <ResponsiveAppBar />}
        <Routes>
            <Route path="/login" element={!authState.authenticated ? <Login /> : <Navigate replace to="/" />} />
            {/* Authenticated Routes */}
            {authState.authenticated ? (
              <>
                <Route path="/" element={<ListView />} />
                <Route path="/well" element={<Well />} />
                <Route path="/well/:wellId" element={<DWR />} />
                <Route path="/account" element={<UserAccount />} />
                <Route path="/settings" element={<SettingsPage />} />
                {/* More authenticated routes as needed */}
              </>
            ) : (
              <Route path="*" element={<Navigate replace to="/login" />} />
            )}
        </Routes>
      </>
    );
};

export default Layout;
