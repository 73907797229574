import React, { useState, useEffect, useCallback } from 'react';
import { useAuth, CACHED_WELLS } from '../api/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import WellRows from './WellRows';
import CreateWellForm from './CreateWellForm';
import CreateBatteryWellForm from './CreateBatteryWellForm'; // Import the new form
import NPTReportView from './NPTReportView'; // Import the NPTReportView component
import DWRListView from './DWRList';
import '../styles/ListView.css';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CreateContactForm from './CreateContactForm';

// Configuration variable
const ALWAYS_REFRESH = true; // Set to true to always fetch from the database, false to use cache

// Get App Url
const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const ListView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { onLogout, authState, isSuperuser } = useAuth();
  const [wells, setWells] = useState([]);
  const [rig, setRig] = useState();
  const [activeTab, setActiveTab] = useState('wells');
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateWellForm, setShowCreateWellForm] = useState(false);
  const [showCreateBatteryWellForm, setShowCreateBatteryWellForm] = useState(false); // New state for battery well form
  const [showCreateContactForm, setShowCreateContactForm] = useState(false);
  const isManagerOrSuperuser = authState.user && (authState.user.is_superuser || authState.user.is_manager);


  const updatedWell = location.state?.updatedWell;

  // console.log('Is superuser:', authState.user.is_superuser);  // Debugging output to verify superuser status

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (location.state && location.state.forceReset) {
      setActiveTab('wells');
      // setSelectedDwr(null);
      // Reset any other relevant state here
    }
  }, [location.state]);

  // const selectWell = (well) => {
  //   navigate('/well', { state: { well } });
  //   console.log('SELECT WELL RESPONSE: ', well);
  // };

  // Update the selectWell function
  const selectWell = async (well) => {
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${appUrl}/jmrapp/wells/${well.id}/detailed/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      // console.log('Detailed Well Response :', response.data)
      navigate('/well', { state: { well: response.data } });
    } catch (error) {
      console.error('Error fetching detailed well data:', error);
    }
  };



  const handleWellUpdated = useCallback((updatedWell) => {
    setWells((prevWells) =>
      prevWells.map((well) => (well.id === updatedWell.id ? updatedWell : well))
    );
  }, []);

  useEffect(() => {
    if (updatedWell) {
      setWells((prevWells) =>
        prevWells.map((well) => (well.id === updatedWell.id ? updatedWell : well))
      );
    }
  }, [updatedWell]);

  useEffect(() => {
    const fetchWellsData = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('my-jwt');
        const response = await axios.get(appUrl + `/jmrapp/wells/basic/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        // console.log("Axios response data:", response.data);
        // console.log('AUTHSTATE : ', authState);

        let filteredWells = response.data;

        // Uncomment and modify the following lines if you need to filter wells based on user roles
        // if (authState.user) {
        //   if (authState.user.is_superuser) {
        //     filteredWells = response.data;
        //   } else if (authState.user.is_manager) {
        //     filteredWells = response.data.filter(well =>
        //       well.assigned_rig !== null && authState.user.managed_rigs.includes(well.assigned_rig)
        //     );
        //   } else if (authState.user.is_staff) {
        //     filteredWells = response.data.filter(well => well.assigned_rig === authState.user.assigned_rig);
        //   }
        // }

        setWells(filteredWells);
        localStorage.setItem(CACHED_WELLS, JSON.stringify(filteredWells));
      } catch (error) {
        console.error('Error fetching wells:', error);
      }
      setIsLoading(false);
    };

    if (authState.authenticated) {
      if (ALWAYS_REFRESH) {
        fetchWellsData();
      } else {
        const cachedWells = localStorage.getItem(CACHED_WELLS);
        if (cachedWells) {
          setWells(JSON.parse(cachedWells));
          setIsLoading(false);
        } else {
          fetchWellsData();
        }
      }
    }
  }, [authState.authenticated, authState.user]);

  useEffect(() => {
    if (!authState.authenticated) {
      setWells([]);
    }
  }, [authState.authenticated]);

  // Populate Create Well Form
  const handleCreateWellClick = () => {
    setShowCreateWellForm(true);
  };

  // Close Create Well Form
  const handleCreateWellClose = () => {
    setShowCreateWellForm(false);
  };

  const handleCreateWellSuccess = (newWell) => {
    setWells((prevWells) => [...prevWells, newWell]);
  };

  // New functions for CreateBatteryWellForm
  const handleCreateBatteryWellClick = () => {
    setShowCreateBatteryWellForm(true);
  };
  const handleCreateBatteryWellClose = () => {
    setShowCreateBatteryWellForm(false);
  };
  const handleCreateBatteryWellSuccess = (newWell) => {
    setWells((prevWells) => [...prevWells, newWell]);
  };

  const handleCreateContactClick = () => {
    setShowCreateContactForm(true);
  };

  const handleCreateContactClose = () => {
    setShowCreateContactForm(false);
  };

  const handleCreateContactSuccess = (newContact) => {
    // console.log('New contact created:', newContact);
  };

  return (
    <>
      <div className='items'>
        <ul className='list-tab'>
          <li className={activeTab === 'wells' ? 'active' : ''} onClick={() => handleTabClick('wells')}>Wells</li>
          {isManagerOrSuperuser && (
            <li className={activeTab === 'dwrs' ? 'active' : ''} onClick={() => handleTabClick('dwrs')}>DWRs</li>
          )}
          {isManagerOrSuperuser && (
            <li className={activeTab === 'npt-reporting' ? 'active' : ''} onClick={() => handleTabClick('npt-reporting')}>NPT Reporting</li>
          )}
          {isManagerOrSuperuser && (
            <li style={{ marginLeft: 'auto' }}>
              <button className="btn create-contact-button" onClick={handleCreateContactClick}>Create Contact</button>
              <button className="btn create-well-button" onClick={handleCreateWellClick}>Create Well</button>
              <button className="btn create-battery-well-button" onClick={handleCreateBatteryWellClick}>Create Battery Well</button>
            </li>
          )}
        </ul>
      </div>
      {showCreateContactForm && (
        <CreateContactForm
          onClose={handleCreateContactClose}
          onContactCreated={handleCreateContactSuccess}
        />
      )}
      {showCreateWellForm && (
        <CreateWellForm
          onClose={handleCreateWellClose}
          onWellCreated={handleCreateWellSuccess}
        />
      )}
      {showCreateBatteryWellForm && (
        <CreateBatteryWellForm
          onClose={handleCreateBatteryWellClose}
          onWellCreated={handleCreateBatteryWellSuccess}
        />
      )}
      {activeTab === 'wells' && (isLoading ? <p>Loading...</p> : <WellRows data={wells} onSelectWell={selectWell} />)}
      {activeTab === 'dwrs' && isManagerOrSuperuser && <DWRListView />}
      {activeTab === 'npt-reporting' && isManagerOrSuperuser && <NPTReportView />}
    </>
  );
};

export default ListView;
