import React, { useState, useEffect } from 'react';
import '../styles/WellForm.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../api/AuthContext';
import { Typography, Alert } from '@mui/material';

// Get App Url
const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const WellForm = ({ well, disabled, onWellUpdated }) => {
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [jobNumber, setJobNumber] = useState('');
  const [rigNo, setRigNo] = useState('');
  const [area, setArea] = useState('');
  const [customerRepresentative, setCustomerRepresentative] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [api, setAPI] = useState('xx-xxx-xxxxx'); // Default value for the API field
  const [dwrDate, setDWRDate] = useState('');
  const [status, setStatus] = useState('');
  const [wellName, setWellName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [rigs, setRigs] = useState([]);
  const [group, setGroup] = useState('');
  const [state, setState] = useState('');
  const [county, setCounty] = useState('');
  const [afePo, setAfePo] = useState('');
  const [netsuiteId, setNetsuiteId] = useState('');
  const [selectedCustomerNetsuiteId, setSelectedCustomerNetsuiteId] = useState('');
  const [selectedRigNetsuiteId, setSelectedRigNetsuiteId] = useState('');
  const { authState } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomersAndRigs = async () => {
      try {
        const token = localStorage.getItem('my-jwt');
        const [customersResponse, rigsResponse] = await Promise.all([
          axios.get(`${appUrl}/jmrapp/customers/`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }),
          axios.get(`${appUrl}/jmrapp/rigs/`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }),
        ]);

        setCustomers(customersResponse.data);
        setRigs(rigsResponse.data);
      } catch (error) {
        console.error('Error fetching customers and rigs:', error);
      }
    };

    fetchCustomersAndRigs();
  }, []);

  useEffect(() => {
    if (well && customers.length > 0 && rigs.length > 0) {
      // Ensure well and nested properties are defined before accessing them
      setCustomerName(well.customer?.customer_name || '');
      setCustomerId(well.customer?.customer_id?.toString() || '');
      setCustomerAddress(well.customer_address || '');
      setCounty(well.county || '');
      setState(well.state || '');
      setJobNumber(well.rrc_job_number || '');
      setArea(well.area || '');
      setCustomerRepresentative(well.customer_representative || '');
      setLatitude(well.latitude || '');
      setLongitude(well.longitude || '');
      setAPI(well.api || 'xx-xxx-xxxxx');
      setDWRDate(well.dwrDate || '');
      setStatus(well.status || '');
      setWellName(well.name || '');
      setAfePo(well.afe_po || '');
      setNetsuiteId(well.netsuite_id || '');
      
      const selectedCustomer = customers.find(c => c.id === well.customer?.id); // Ensure 'well.customer' is defined

      if (selectedCustomer) {
        setCustomerId(selectedCustomer.id.toString()); // This might be redundant
        setSelectedCustomerNetsuiteId(selectedCustomer.ns_iid || well.customer_nsid || '');
      }

      const selectedRig = rigs.find(r => r.name === well.assigned_rig?.name); // Ensure 'well.assigned_rig' is defined
      if (selectedRig) {
        setRigNo(selectedRig.id.toString());
        setSelectedRigNetsuiteId(selectedRig.netsuite_id || '');
      }
    }
  }, [well, customers, rigs]);

  const formatAPI = (value) => {
    // Remove any non-digit characters
    const digits = value.replace(/\D/g, '');

    // Format to "xx-xxx-xxxxx" with placeholders
    let formatted = "xx-xxx-xxxxx";
    
    // Replace placeholder characters with user input
    for (let i = 0; i < digits.length && i < 10; i++) {
      formatted = formatted.replace('x', digits[i]);
    }
    
    return formatted;
  };

  const handleAPIChange = (e) => {
    const formattedAPI = formatAPI(e.target.value);
    setAPI(formattedAPI);
  };

  const updateNetSuite = async (data, netsuiteId) => {
    try {
      const token = localStorage.getItem('my-jwt');
      const method = 'PATCH';
      const url = `${appUrl}/proxyapp/proxy/`;
      const netsuiteResponse = await axios({
        method,
        url,
        data: { ...data, netsuite_id: netsuiteId },
        headers: { 'Authorization': `Bearer ${token}` }
      });

      return netsuiteResponse.status === 200;
    } catch (error) {
      console.error('Error updating NetSuite:', error);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const netsuiteData = {
      companyName: wellName,
      projectExpenseType: -2,
      custentity_api_number: api,
      custentity_rig: selectedRigNetsuiteId,
      entityStatus: 2,
      parent_netsuite_id: selectedCustomerNetsuiteId,
      subsidiary: { id: "4" }
    };

    if (api.includes('x')) {
      alert('The API number is incomplete. Please ensure the API number is fully entered without any "x"s.');
      setIsEditing(false);
      return;
    }

    const success = await updateNetSuite(netsuiteData, netsuiteId);

    if (success) {
      try {
        const token = localStorage.getItem('my-jwt');
        const response = await axios.patch(`${appUrl}/jmrapp/wells/${well.id}/`, {
          api,
          area,
          customer_representative: customerRepresentative,
          latitude,
          county,
          state,
          longitude,
          rrc_job_number: jobNumber,
          status,
          name: wellName,
          afe_po: afePo,
          customer_id: customerId,
          assigned_rig: parseInt(rigNo),
          assigned_rig_id: parseInt(rigNo),
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        setIsEditing(false);
        if (onWellUpdated) {
          onWellUpdated(response.data);
        }
        navigate('/', { state: { updatedWell: response.data } });
      } catch (error) {
        console.error('Error updating well:', error);
      }
    }
  };

  const handleEditClick = (event) => {
    event.preventDefault();
    if (!disabled) {
      setIsEditing((prevState) => !prevState);
    }
  };

const canEdit = (authState.user?.is_manager || authState.user?.is_superuser) && !disabled;
    
  return (
    <form className='form' onSubmit={handleSubmit}>
      <div className='formRow'>
        <div className='formCol'>
          <label className='formLabel' htmlFor="customer">Customer <span className="required">*</span></label>
          <select
            className='formInput'
            id="customer"
            value={customerId}
            onChange={(e) => {
              setCustomerId(e.target.value);
              const selectedCustomer = customers.find(customer => customer.id.toString() === e.target.value);
              setCustomerName(selectedCustomer?.name || '');
              setCustomerAddress(selectedCustomer?.address || '');
              setSelectedCustomerNetsuiteId(selectedCustomer?.ns_iid || '');
            }}
            disabled={!isEditing}
            required
          >
            <option value="">Select a customer</option>
            {customers.map((customer) => (
              <option key={customer.id} value={customer.id.toString()}>
                {customer.name}
              </option>
            ))}
          </select>
        </div>

        <div className='formCol'>
          <label className='formLabel' htmlFor="assignedRig">Assigned Rig <span className="required">*</span></label>
          <select
            className='formInput'
            id="assignedRig"
            value={rigNo}
            onChange={(e) => {
              setRigNo(e.target.value);
              const selectedRig = rigs.find(rig => rig.id.toString() === e.target.value);
              setSelectedRigNetsuiteId(selectedRig?.netsuite_id || '');
            }}
            disabled={!isEditing}
            required
          >
            <option value="">Select a rig</option>
            {rigs.map((rig) => (
              <option key={rig.id} value={rig.id.toString()}>
                {rig.name}
              </option>
            ))}
          </select>
        </div>

        <div className='formCol'>
          <label className='formLabel5' htmlFor="area">Area Category <span className="required">*</span></label>
          <span className='formSpan'>
            <select className='formInput5' id="area" value={area} onChange={(e) => setArea(e.target.value)} disabled={!isEditing} required>
              <option value="">Select an area</option>
              <option value="Permian">Permian</option>
              <option value="DJ Basin">DJ Basin</option>
              <option value="San Juan Basin">San Juan Basin</option>
              <option value="Deleware Basin">Delaware Basin</option>
              <option value="Reclamation">Reclamation</option>
            </select>
          </span>
        </div>
        <div className='formCol3'>
          <label className='formLabel2' htmlFor="wellName">Well Name <span className="required">*</span></label>
          <input className='formInput2' type="text" value={wellName} onChange={(e) => setWellName(e.target.value)} disabled={!isEditing} required />
        </div>
      </div>

      <div className='formRow2'>
        <div className='formCol3'>
          <label className='formLabel3' htmlFor="afePo">AFE/PO No.</label>
          <input className='formInput3' type="text" value={afePo} onChange={(e) => setAfePo(e.target.value)} disabled={!isEditing} />
        </div>
        <div className='formCol3'>
          <label className='formLabel2' htmlFor="api">API Number <span className="required">*</span></label>
          <input className='formInput2' type="text" value={api} onChange={handleAPIChange} disabled={!isEditing} required />
        </div>
      <div className='formCol3'>
        <label className='formLabel3' htmlFor="jobNumber">Job Number</label>
        <input className='formInput3' type="text" value={jobNumber} onChange={(e) => setJobNumber(e.target.value)} disabled={!isEditing} />
      </div>
        <div className='formCol3'>
          <label className='formLabel3' htmlFor="customerRepresentative">Customer Representative</label>
          <input className='formInput3' type="text" value={customerRepresentative} onChange={(e) => setCustomerRepresentative(e.target.value)} disabled={!isEditing} />
        </div>
      </div>

      <div className='formRow3'>
        <div className='formCol3'>
          <label className='formLabel3' htmlFor="county">County</label>
          <input className='formInput3' type="text" value={county} onChange={(e) => setCounty(e.target.value)} disabled={!isEditing} />
        </div>
        <div className='formCol3'>
          <label className='formLabel3' htmlFor="state">State</label>
          <input className='formInput3' type="text" value={state} onChange={(e) => setState(e.target.value)} disabled={!isEditing} />
        </div>
        <div className='formCol3'>
          <label className='formLabel' htmlFor="latitude">Latitude</label>
          <input className='formInput' type="number" value={latitude} onChange={(e) => setLatitude(e.target.value.toString())} disabled={!isEditing} />
          <Alert severity="warning" sx={{ mt: 1, py: 0 }}>
            <Typography variant="caption">
              Latitude value must not exceed 6 decimal places (Example: 123.456789)
            </Typography>
          </Alert>
        </div>
        <div className='formCol3'>
          <label className='formLabel' htmlFor="longitude">Longitude</label>
          <input className='formInput' type="number" value={longitude} onChange={(e) => setLongitude(e.target.value)} disabled={!isEditing} />
          <Alert severity="warning" sx={{ mt: 1, py: 0 }}>
            <Typography variant="caption">
            Longitude value must not exceed 6 decimal places (Example: 123.456789)
            </Typography>
          </Alert>
        </div>
      </div>

      <div className='formRow5'>
        <div>
          <label className='formLabel' htmlFor="wellStatus">Well Status <span className="required">*</span></label>
          <select className='formInput' name="wellStatus" id="wellStatus" value={status} onChange={(e) => setStatus(e.target.value)} disabled={!isEditing} required>
            <option value="In Progress">In Progress</option>
            <option value="Awarded">Awarded</option>
            <option value="Closed">Closed</option>
          </select>
        </div>
      </div>

      <div className='formFooter'>
        {canEdit && (
          isEditing ? (
            <button className="saveButton hover" type="submit">
              Submit Changes
            </button>
          ) : (
            <button className="editButton hover" type="button" onClick={handleEditClick}>
              Edit
            </button>
          )
        )}
      </div>
    </form>
  );
};

export default WellForm;
