import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';

const CreateWellForm = ({ onClose, onWellCreated }) => {
  const [open, setOpen] = useState(true);
  const [apiNumber, setApiNumber] = useState('xx-xxx-xxxxx');
  const [stagingWell, setStagingWell] = useState(null);
  const [customerId, setCustomerId] = useState('');
  const [rigNo, setRigNo] = useState('');
  const [rrcJobNumber, setRrcJobNumber] = useState('');
  const [area, setArea] = useState('');
  const [customers, setCustomers] = useState([]);
  const [rigs, setRigs] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCustomerNetsuiteId, setSelectedCustomerNetsuiteId] = useState('');
  const [selectedRigNetsuiteId, setSelectedRigNetsuiteId] = useState('');

    // Get App URL
    const appUrl = process.env.REACT_APP_APP_URL;

    if (!appUrl) {
      throw new Error('REACT_APP_APP_URL is not defined. Please set it in your .env file.');
    }

  const areaOptions = [
    'Deleware Basin',
    'DJ Basin',
    'Permian',
    'San Juan Basin',
    'Reclamation'
  ];



  useEffect(() => {
    const fetchCustomersAndRigs = async () => {
      try {
        const token = localStorage.getItem('my-jwt');
        const [customersResponse, rigsResponse] = await Promise.all([
          axios.get(`${appUrl}/jmrapp/customers/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${appUrl}/jmrapp/rigs/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        setCustomers(customersResponse.data);
        setRigs(rigsResponse.data);
      } catch (error) {
        console.error('Error fetching customers and rigs:', error);
      }
    };

    fetchCustomersAndRigs();
  }, [appUrl]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const formatAPI = (value) => {
    const digits = value.replace(/\D/g, '');
    let formatted = 'xx-xxx-xxxxx';
    for (let i = 0; i < digits.length && i < 10; i++) {
      formatted = formatted.replace('x', digits[i]);
    }
    return formatted;
  };

  const handleApiNumberChange = (e) => {
    const formattedAPI = formatAPI(e.target.value);
    setApiNumber(formattedAPI);
  };

  const handleApiSubmit = async () => {
    if (apiNumber.includes('x')) {
      setError('Please enter a 10-digit API number.');
      return;
    }
    setLoading(true);
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${appUrl}/jmrapp/staging-well/`, {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { api_number: apiNumber }
      });
      setStagingWell(response.data);
      setArea(response.data.di_basin || '');
      setError('');
    } catch (error) {
      console.error('Error fetching staging well:', error);
      setError('No matching API number found or well has already been created');
    } finally {
      setLoading(false);
    }
  };


  const handleCustomerChange = (e) => {
    //console.log('Customers: ', customers);
    const selectedCustomerId = e.target.value;
    //console.log('Selected Customer ID: ', selectedCustomerId);
    setCustomerId(selectedCustomerId);

    const selectedCustomer = customers.find((customer) => customer.id === parseInt(selectedCustomerId, 10));
    if (selectedCustomer && selectedCustomer.ns_iid) {
      setSelectedCustomerNetsuiteId(selectedCustomer.ns_iid);
      //console.log('Selected customer NetSuite ID (ns_iid):', selectedCustomer.ns_iid);
    } else {
      setSelectedCustomerNetsuiteId('');
      console.error('Selected customer does not have a netsuite_id', selectedCustomer);
    }
  };


  const handleRigChange = (e) => {
    const selectedRigId = e.target.value;
    setRigNo(selectedRigId);

    const selectedRig = rigs.find((rig) => rig.id === parseInt(selectedRigId, 10));
    if (selectedRig && selectedRig.netsuite_id) {
      setSelectedRigNetsuiteId(selectedRig.netsuite_id);
      //console.log('Selected Rig NetSuite ID:', selectedRig.netsuite_id);
    } else {
      setSelectedRigNetsuiteId('');
      console.error('Selected rig does not have a netsuite_id', selectedRig);
    }
  };


  const handleCreateWell = async () => {
    setLoading(true);
    setError('');

    if (!customerId || !rigNo || !area || !apiNumber || apiNumber.includes('x')) {
      setError('Please fill in all required fields: Customer, Assigned Rig, Area, and API Number.');
      setLoading(false);
      return;
    }

    if (!selectedCustomerNetsuiteId || !selectedRigNetsuiteId) {
      setError('Selected Customer or Rig is missing NetSuite ID. Please try selecting again.');
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('my-jwt');

      // Step 1: Create well in NetSuite
      const netsuiteData = {
        companyName: `${stagingWell.well_name} ${stagingWell.well_number}`.trim(),
        projectExpenseType: -2,
        custentity_api_number: apiNumber,
        custentity_rig: selectedRigNetsuiteId,
        parent_netsuite_id: selectedCustomerNetsuiteId,
        entityStatus: 2,
        subsidiary: { id: "4" }
      };

      //console.log('NetSuite request data:', netsuiteData);

      const netsuiteResponse = await axios.post(`${appUrl}/proxyapp/proxy/`, netsuiteData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      console.log('NetSuite response:', netsuiteResponse.data);

      if (!netsuiteResponse.data || !netsuiteResponse.data.id) {
        throw new Error('Failed to create well in NetSuite');
      }

      const netsuiteId = netsuiteResponse.data.id;

      // Step 2: Create well in local database
      const wellData = {
        ...stagingWell,
        customer_id: parseInt(customerId, 10),  // Ensure customer_id is an integer
        assigned_rig_id: parseInt(rigNo, 10), 
        rrc_job_number: rrcJobNumber,
        area: area,
        netsuite_id: netsuiteId,
        name: `${stagingWell.well_name} ${stagingWell.well_number}`.trim(),
        api: apiNumber,
        state: stagingWell.state,
        county: stagingWell.county,
        latitude: parseFloat(stagingWell.latitude),
        longitude: parseFloat(stagingWell.longitude),
        operator: stagingWell.operator

      };

      console.log('Local DB request data:', wellData);

      try {
        const localDbResponse = await axios.post(`${appUrl}/jmrapp/create-well-from-staging/`, wellData, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        console.log('Local DB response:', localDbResponse.data);

        if (!localDbResponse.data.id) {
          throw new Error('Well creation in local database failed');
        }

        onWellCreated(localDbResponse.data);
        handleClose();
      } catch (localDbError) {
        console.error('Error creating well in local database:', localDbError);
        console.error('Error response:', localDbError.response?.data);

        // Step 3: If local DB creation fails, delete from NetSuite
        try {
          await axios.delete(`${appUrl}/proxyapp/delete_project/${netsuiteId}/`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          //console.log('Well deleted from NetSuite due to local DB creation failure');
        } catch (deleteError) {
          console.error('Error deleting well from NetSuite:', deleteError);
          setError('Well created in NetSuite but failed in local database. Manual reconciliation required.');
        }

        throw new Error('Failed to create well in local database. Changes in NetSuite were rolled back.');
      }
    } catch (error) {
      console.error('Error in well creation process:', error);
      setError(error.message || 'Failed to create well. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Create New Well</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {!stagingWell ? (
            <TextField
              label="API Number"
              value={apiNumber}
              onChange={handleApiNumberChange}
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder='xx-xxx-xxxxx'
            />
          ) : (
            <>
              <Typography variant="body1" gutterBottom>API Number: {apiNumber}</Typography>
              <Typography variant="body1" gutterBottom>Well Name: {stagingWell.well_name}</Typography>
              <Typography variant="body1" gutterBottom>Well Number: {stagingWell.well_number}</Typography>
              <Typography variant="body1" gutterBottom>Operator: {stagingWell.operator}</Typography>
              <Typography variant="body1" gutterBottom>State: {stagingWell.state}</Typography>
              <Typography variant="body1" gutterBottom>County: {stagingWell.county}</Typography>
              <Typography variant="body1" gutterBottom>Latitude: {stagingWell.latitude}</Typography>
              <Typography variant="body1" gutterBottom>Longitude: {stagingWell.longitude}</Typography>

              <FormControl fullWidth margin="normal" variant="outlined" required>
                <InputLabel>Customer</InputLabel>
                <Select
                  value={customerId}
                  onChange={handleCustomerChange}
                  label="Customer"
                >
                  <MenuItem value="">Select a customer</MenuItem>
                  {customers.sort((a, b) => a.name.localeCompare(b.name)).map((customer) => (
                    <MenuItem key={customer.id} value={customer.id}>{customer.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal" variant="outlined" required>
                <InputLabel>Assigned Rig</InputLabel>
                <Select
                  value={rigNo}
                  onChange={handleRigChange}
                  label="Assigned Rig"
                >
                  {rigs.map((r) => (
                    <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Area</InputLabel>
                <Select
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                  label="Area"
                >
                  {areaOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="RRC Job Number"
                value={rrcJobNumber}
                onChange={(e) => setRrcJobNumber(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </>
          )}
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {!stagingWell ? (
          <Button onClick={handleApiSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Submit API'}
          </Button>
        ) : (
          <Button onClick={handleCreateWell} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Create Well'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateWellForm;
