import React, { useState } from 'react';
import { Modal, Box, List, ListItemButton, ListItemText, Checkbox, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
const TimeRecordModal = ({ isOpen, onClose, onSelect, roles, selectedRoles }) => {
  const [selectedRoleIds, setSelectedRoleIds] = useState([]);
  const [selectedRoleCounts, setSelectedRoleCounts] = useState({});

  const handleToggle = (id) => {
    const currentIndex = selectedRoleIds.indexOf(id);
    const newChecked = [...selectedRoleIds];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedRoleIds(newChecked);
  };

  const handleCountChange = (id, count) => {
    setSelectedRoleCounts((prevCounts) => ({
      ...prevCounts,
      [id]: count,
    }));
  };

  const handleSubmit = () => {
    const selectedRoleObjects = roles.filter((role) => selectedRoleIds.includes(role.id));
    const selectedRolesWithCounts = selectedRoleObjects.map((role) => ({
      ...role,
      count: selectedRoleCounts[role.id] || 1,
    }));

    onSelect(selectedRolesWithCounts);
    setSelectedRoleIds([]);
    setSelectedRoleCounts({});
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
        <List dense>
          {roles.map((role) => (
            <ListItemButton
              key={role.id}
              onClick={(event) => {
                if (event.target.type === 'checkbox') {
                  handleToggle(role.id);
                }
              }}
            >
              <Checkbox
                checked={selectedRoleIds.includes(role.id)}
                tabIndex={-1}
                disableRipple
                onChange={() => handleToggle(role.id)}
              />
              <ListItemText primary={role.title} />
              {selectedRoleIds.includes(role.id) && (
                <FormControl>
                  <InputLabel>Count</InputLabel>
                  <Select
                    value={selectedRoleCounts[role.id] || 1}
                    onChange={(e) => handleCountChange(role.id, e.target.value)}
                  >
                    {[1, 2, 3, 4, 5].map((count) => (
                      <MenuItem key={count} value={count}>
                        {count}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </ListItemButton>
          ))}
        </List>
        <Button onClick={handleSubmit}>Select Roles</Button>
      </Box>
    </Modal>
  );
};

export default TimeRecordModal;
