import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types"; // Import PropTypes for type checking
import WellForm from "./WellForm";
import WorkAssignment from "./WorkAssignment";
import WorkDescriptionsModal from "./WorkDescriptionsModal";
import ChargeRecordModal from "./ChargeRecordModal";
import ChargeRecord from "./ChargeRecord";
import TimeRecord from "./TimeRecord";
import TimeRecordModal from "./TimeRecordModal";
import { useAuth } from "../api/AuthContext";
import "../styles/DWRForm.css";
import "../styles/WellForm.css";
import { convertTo24Hour } from "./convertTo24Hour";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { createAndUploadCSV } from "./create_and_upload_csv"; // Import the new script
import { createXLSX } from './create_and_download_xlsx';
import { Box, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Typography, Switch, FormGroup, FormControlLabel, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';



const appUrl = process.env.REACT_APP_APP_URL;
if (!appUrl) {
  throw new Error(
    "REACT_APP_APP_URL is not defined. Please set it in your .env file."
  );
}

const DWRForm = ({ selectedDwr, setSelectedDwr, onClose, getAdjacentDWR, fetchFullDWRData }) => {
  const { authState } = useAuth();
  const currentUser = authState?.user;
  const token = authState?.token;
  const location = useLocation();
  const well = location.state?.well;
  const [isLoading, setIsLoading] = useState(true);

// console.log('DWR FORM WELL DATA: ', well)

  const initialFormState = {
    id: null,
    date: "",
    day: "",
    isLastDay: "",
    ticketNumber: "",
    contact: null,
    workAssignments: [],
    workDescriptions: [],
    chargeRecords: {
      onTurnkey: [],
      offTurnkey: [],
    },
    timeRecords: [],
  };

  const [formData, setFormData] = useState(initialFormState);
  const [workAssignments, setWorkAssignments] = useState([]);
  const [isLoadingWorkDescriptions, setIsLoadingWorkDescriptions] =
    useState(false);
  const [workDescriptions, setWorkDescriptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rigData, setRigData] = useState(null);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [serviceItems, setServiceItems] = useState([]);
  const [selectedInventoryObjects, setSelectedInventoryObjects] = useState([]);
  const [selectedServiceObjects, setSelectedServiceObjects] = useState([]);
  const [isChargeModalOpen, setIsChargeModalOpen] = useState({
    onTurnkey: false,
    offTurnkey: false,
  });
  const [selectedInventoryItems, setSelectedInventoryItems] = useState([]);
  const [selectedServiceItems, setSelectedServiceItems] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [onTurnkeyChargeItems, setOnTurnkeyChargeItems] = useState([]);
  const [offTurnkeyChargeItems, setOffTurnkeyChargeItems] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [workDescAssignments, setWorkDescAssignments] = useState({});
  const [currentDwrId, setCurrentDwrId] = useState(null);
  const [chargeRecordIds, setChargeRecordIds] = useState({
    onTurnkey: null,
    offTurnkey: null,
  });
  const [timeRecordId, setTimeRecordId] = useState(null);
  const [timeRecordUpdateNeeded, setTimeRecordUpdateNeeded] = useState(false);
  const [showDuplicateChargeError, setShowDuplicateChargeError] =
    useState(false);
  const [updateChargeRecordsTriggered, setUpdateChargeRecordsTriggered] =
    useState(false);
  const [employeeTimeRecords, setEmployeeTimeRecords] = useState([]);
  const [timeRecordSelections, setTimeRecordSelections] = useState([]);
  const [isTimeRecordModalOpen, setIsTimeRecordModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notes, setNotes] = useState("");
  const [notesUpdated, setNotesUpdated] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isDwrLoading, setIsDwrLoading] = useState(true);
  const [areChargesLoading, setAreChargesLoading] = useState(true);
  const [showOnTurnkey, setShowOnTurnkey] = useState(
    well.name === "Chevron DJ Basin"
  );
  const [showChargeRecordWarning, setShowChargeRecordWarning] = useState(false);
  const [initialOnTurnkeyItems, setInitialOnTurnkeyItems] = useState([]);
  const [initialOffTurnkeyItems, setInitialOffTurnkeyItems] = useState([]);
  const [hasLoadedCharges, setHasLoadedCharges] = useState(false);
  const [zeroAllPrices, setZeroAllPrices] = useState(false); // Step 1: Define zeroAllPrices
  const [currentPricebook, setCurrentPricebook] = useState(null);
  const [isLoadingPricebook, setIsLoadingPricebook] = useState(false);
  const [miscellaneousItems, setMiscellaneousItems] = useState([]);
  const [day, setDay] = useState("");
  const [isLastDay, setIsLastDay] = useState (false);
  const [ticketNumber, setTicketNumeber] = useState("");
  const [isLocked, setIsLocked] = useState(selectedDwr?.is_locked || false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isApproved, setIsApproved] = useState(selectedDwr?.is_approved || false);



  const timeRecordRef = useRef();

  const updateQuantities = (id, quantity) => {
    setQuantities((prev) => {
      const updated = { ...prev, [id]: quantity };
      // console.log("DWRForm: Updated quantities:", updated);
      return updated;
    });
  };

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const hasPermission = (permission) => {
    return currentUser && currentUser.permissions && currentUser.permissions.includes(permission);
  };

  

  useEffect(() => {
    if (selectedDwr) {
      setIsDwrLoading(true);
      setAreChargesLoading(true);
      setIsLocked(selectedDwr.is_locked);
      setIsApproved(selectedDwr.is_approved);
      setIsApproved(selectedDwr.approval_status === 'approved');
      console.log("SELECTED DWR: ", selectedDwr);
      // console.log("Charge Records:", selectedDwr.charge_records);


      const onTurnkeyCharges = selectedDwr.charge_records.filter(
        (record) => record.on_turnkey
      );
      const offTurnkeyCharges = selectedDwr.charge_records.filter(
        (record) => !record.on_turnkey
      );

        // Step 2: Update zeroAllPrices based on the condition
        if (offTurnkeyCharges.length === 0 && onTurnkeyCharges.length > 0) {
          setZeroAllPrices(true);
      } else {
          setZeroAllPrices(false);
      }

      setFormData({
        id: selectedDwr.id,
        date: selectedDwr.date,
        day: selectedDwr.day, // Add day to initial form state
        isLastDay: selectedDwr.isLastDay,
        ticketNumber: selectedDwr.ticket_number,
        is_locked: selectedDwr.is_locked,
        is_approved: selectedDwr.is_approved,
        notes: selectedDwr.notes || "",
        contact_id: selectedDwr.contact ? selectedDwr.contact.id : null,
        chargeRecords: {
          onTurnkey: onTurnkeyCharges,
          offTurnkey: offTurnkeyCharges,
        },
      });
      setDay(selectedDwr.day)
      setTicketNumeber(selectedDwr.ticketNumber)
      setNotes(selectedDwr.notes || "");
      setNotesUpdated(false);
      setIsLastDay(Boolean(selectedDwr.is_last_day));
      setIsLocked(selectedDwr.is_locked);
      setIsApproved(selectedDwr.is_approved);


      // Set charge record IDs
      setChargeRecordIds({
        onTurnkey: onTurnkeyCharges.length > 0 ? onTurnkeyCharges[0].id : null,
        offTurnkey:
          offTurnkeyCharges.length > 0 ? offTurnkeyCharges[0].id : null,
      });

      setWorkAssignments(
        selectedDwr.work_assignments
          .map((assignment) => ({
            ...assignment,
            description: assignment.description,
            fromTime: assignment.from_time,
            toTime: assignment.to_time,
          }))
          .sort((a, b) => a.fromTime.localeCompare(b.fromTime))
      );

      setIsDwrLoading(false);
    } else {
      setFormData(initialFormState);
      setDay("")
      setNotes("");
      setTicketNumeber("");
      setNotesUpdated(false);
      setWorkAssignments([]);
      setIsDwrLoading(false);
      setAreChargesLoading(false);
    }

    // console.log("SELECTED DWR: ", selectedDwr);
  }, [selectedDwr]);


  useEffect(() => {
    const fetchContacts = async () => {
      try {
        if (!well) {
          // console.log("Well data is not available");
          return;
        }

        const response = await axios.get(appUrl + `/jmrapp/contacts/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const filteredContacts = response.data.filter(
          (contact) => contact.company_id === well.customer.ns_iid
        );

        setContacts(filteredContacts);
      } catch (error) {
        console.error("Failed to fetch contacts:", error);
      }
    };

    fetchContacts();
  }, [token, well]);

  // Function to handle CSV upload button click
// Function to handle CSV upload button click
const handleUploadCSVClick = async () => {
if (!selectedDwr || isLocked) {
    console.error("Cannot create sales order: DWR is not selected or is already locked");
    return;
}

try {
    // Create and upload CSV with the form data
    console.log('FULL DWR DATA:', selectedDwr);

    const csvSuccess = await createAndUploadCSV(selectedDwr || formData, zeroAllPrices, authState);

    if (csvSuccess) {

        // Update so_created_at field
        const updateResponse = await axios.patch(
          `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/`,
          { 
            create_sales_order: true,
            is_locked: true,
            is_last_day: isLastDay  // Include the is_last_day information
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        // Lock the DWR only if CSV creation/upload was successful
        await handleManualLock();

        // Update local state
        setIsLocked(true);
        setSelectedDwr(prevDwr => ({
          ...prevDwr,
          is_locked: true,
          so_created_at: updateResponse.data.so_created_at,
          is_last_day: isLastDay
        }));
        // Show success message
        confirmAlert({
            title: "Success",
            message: "Sales order created and DWR locked successfully.",
            buttons: [{ label: "OK", onClick: () => {} }],
        });
    } else {
        // Show error message if CSV creation/upload fails
        confirmAlert({
            title: "Error",
            message: "Failed to create sales order or lock DWR. Please try again.",
            buttons: [{ label: "OK", onClick: () => {} }],
        });
    }
} catch (error) {
    console.error("Failed to create sales order or lock DWR:", error);
    confirmAlert({
        title: "Error",
        message: "Failed to create sales order or lock DWR. Please try again.",
        buttons: [{ label: "OK", onClick: () => {} }],
    });
}
};

  // Function to handle CSV upload button click
  const handleCreateXSLXClick = () => {
    const data = selectedDwr || formData;
    createXLSX(data, zeroAllPrices, authState, '/open_invoice_template.xlsx');
  };

  useEffect(() => {
    const fetchItemDetails = async () => {
      if (selectedDwr && selectedDwr.charge_records.length > 0 && !hasLoadedCharges) {
        setAreChargesLoading(true);
        // console.log('Processing charge records:', selectedDwr.charge_records);
  
        const chargeRecordPromises = selectedDwr.charge_records.map(async (chargeRecord) => {
          // console.log('Processing charge record:', chargeRecord);
          const [serviceItemDetails, inventoryItemDetails, miscellaneousItemDetails] = await Promise.all([
            Promise.all(
              chargeRecord.service_item_charges.map((charge) =>
                axios.get(`${appUrl}/jmrapp/serviceitems/${charge.service_item}`, {
                  headers: { Authorization: `Bearer ${token}` },
                }).then((response) => ({
                  chargeId: charge.id,
                  id: charge.service_item,
                  type: "Service",
                  quantity_used: parseFloat(charge.quantity_used),
                  name: response.data.name,
                  unit_type: response.data.unit_type,
                  quantity_available: "N/A",
                  price: response.data.price,
                  endpoint_id: response.data.endpoint_id,
                }))
              )
            ),
            Promise.all(
              chargeRecord.inventory_item_charges.map((charge) =>
                axios.get(`${appUrl}/jmrapp/inventoryitems/${charge.inventory_item}`, {
                  headers: { Authorization: `Bearer ${token}` },
                }).then((response) => ({
                  chargeId: charge.id,
                  id: charge.inventory_item,
                  type: "Inventory",
                  quantity_used: parseFloat(charge.quantity_used),
                  name: response.data.name,
                  unit_type: response.data.unit_type,
                  quantity_available: response.data.quantity_available,
                  price: response.data.price,
                  ns_eid: response.data.ns_eid,
                }))
              )
            ),
            Promise.all(
              chargeRecord.miscellaneous_item_charges.map((charge) =>
                axios.get(`${appUrl}/jmrapp/miscellaneous-item/${charge.miscellaneous_item}/`, {
                  headers: { Authorization: `Bearer ${token}` },
                }).then((response) => ({
                  chargeId: charge.id,
                  id: response.data.id, // Use the id from the API response
                  type: "Miscellaneous",
                  quantity_used: parseFloat(charge.quantity_used),
                  name: charge.custom_name,
                  unit_type: response.data.unit_type || "N/A",
                  price: parseFloat(charge.price_at_use),
                  endpoint_id: response.data.endpoint_id,
                  price_at_use: parseFloat(charge.price_at_use),
                }))
              )
            ),
          ]);
  
          return {
            on_turnkey: chargeRecord.on_turnkey,
            items: [...serviceItemDetails, ...inventoryItemDetails, ...miscellaneousItemDetails],
          };
        });
  
        try {
          const detailedChargeRecords = await Promise.all(chargeRecordPromises);
  
          const onTurnkeyItems = detailedChargeRecords
            .filter((record) => record.on_turnkey)
            .flatMap((record) => record.items);
          const offTurnkeyItems = detailedChargeRecords
            .filter((record) => !record.on_turnkey)
            .flatMap((record) => record.items);
  
          setOnTurnkeyChargeItems(onTurnkeyItems);
          setOffTurnkeyChargeItems(offTurnkeyItems);
  
          setInitialOnTurnkeyItems(onTurnkeyItems);
          setInitialOffTurnkeyItems(offTurnkeyItems);
        } catch (error) {
          console.error("Failed to fetch item details:", error);
        } finally {
          setAreChargesLoading(false);
          setHasLoadedCharges(true);
        }
      }
    };

    const initializeWorkAssignments = () => {
      if (selectedDwr) {
        setWorkAssignments(
          selectedDwr.work_assignments
            .map((assignment) => ({
              ...assignment,
              description: assignment.description,
              fromTime: assignment.from_time,
              toTime: assignment.to_time,
            }))
            .sort((a, b) => a.fromTime.localeCompare(b.fromTime))
        );
      } else {
        setWorkAssignments([]);
      }
    };

    fetchItemDetails();
    // console.log("Are charges loading? ", areChargesLoading);
    initializeWorkAssignments();
  }, [selectedDwr, token, appUrl, hasLoadedCharges]);

  // Reset hasLoadedCharges when selectedDwr changes
  useEffect(() => {
    setHasLoadedCharges(false);
  }, [selectedDwr]);

  const memoizedEmployeeTimeRecords = useMemo(() => {
    return selectedDwr &&
      selectedDwr.time_record &&
      selectedDwr.time_record.employee_time_records
      ? selectedDwr.time_record.employee_time_records
      : [];
  }, [selectedDwr?.time_record?.employee_time_records?.length]);

  const rows = timeRecordRef.current ? timeRecordRef.current.getRowsData() : [];
  // console.log("Rows data:", rows);

  useEffect(() => {
    if (!isSubmitClicked) return;
  
    const prepareAndSubmitDWR = async () => {
      const formDataPayload = {
        date: formData.date,
        ticket_number: formData.ticketNumber,  // Changed to match backend field name
        well_id: well.id,
        notes: notes,
        day: formData.day,
        is_last_day: formData.isLastDay || false,
        contact_id: formData.contact_id || null,
        ...(currentUser ? { created_by: currentUser.id } : {}),
      };
      // console.log("DATA TO CREATE WELL: ", formDataPayload);
  
      const isUpdating = currentDwrId !== null;
      const method = isUpdating ? "put" : "post";
      const url = isUpdating
        ? `${appUrl}/jmrapp/dailyworkrecords/${currentDwrId}/`
        : `${appUrl}/jmrapp/dailyworkrecords/`;
  
      try {
        const response = await axios({
          method: method,
          url: url,
          data: formDataPayload,
          headers: { Authorization: `Bearer ${token}` },
        });
  
        const newDwr = response.data;
        setSelectedDwr(newDwr);
        const dwrIdCreated = newDwr.id;
        setCurrentDwrId(dwrIdCreated);

        if (!isUpdating) {
          const createChargeRecord = async (onTurnkey) => {
            const chargeItems = onTurnkey ? onTurnkeyChargeItems : offTurnkeyChargeItems;
            
            // console.log(`Creating ${onTurnkey ? 'on' : 'off'} turnkey charge record for DWR ${dwrIdCreated}`);
            // console.log(`${onTurnkey ? 'On' : 'Off'} Turnkey Charge Items:`, chargeItems);
    
            const payload = {
              dwr: dwrIdCreated,
              on_turnkey: onTurnkey,
              service_item_charges: chargeItems
                .filter(item => item.type === 'Service')
                .map(item => ({
                  service_item_id: item.id,
                  quantity_used: item.quantity_used
                })),
              inventory_item_charges: chargeItems
                .filter(item => item.type === 'Inventory')
                .map(item => ({
                  inventory_item_id: item.id,
                  quantity_used: item.quantity_used
                })),
              miscellaneous_item_charges: chargeItems
                .filter(item => item.type === 'Miscellaneous')
                .map(item => ({
                  miscellaneous_item_id: item.id,
                  quantity_used: item.quantity_used,
                  custom_name: item.custom_name,
                  price_at_use: item.price_at_use
                }))
            };
    
            // console.log(`${onTurnkey ? 'On' : 'Off'} Turnkey Charge Record Payload:`, payload);
    
            try {
              const response = await axios.post(
                `${appUrl}/jmrapp/chargerecords/`,
                payload,
                { headers: { Authorization: `Bearer ${token}` } }
              );
              // console.log(`${onTurnkey ? 'On' : 'Off'} turnkey charge record created with ID:`, response.data.id);
              return response.data.id;
            } catch (error) {
              console.error(`Error creating ${onTurnkey ? 'on' : 'off'} turnkey charge record:`, error.response?.data);
              throw error;
            }
          };
    
          let onTurnkeyChargeRecordId = null;
          let offTurnkeyChargeRecordId = null;
    
          // Check if on-turnkey items exist before creating the record
          if (onTurnkeyChargeItems.length > 0) {
            onTurnkeyChargeRecordId = await createChargeRecord(true);
          }
    
          // Check if off-turnkey items exist before creating the record
          if (offTurnkeyChargeItems.length > 0) {
            offTurnkeyChargeRecordId = await createChargeRecord(false);
          }
    
          setChargeRecordIds({
            onTurnkey: onTurnkeyChargeRecordId,
            offTurnkey: offTurnkeyChargeRecordId,
          });
    
          await updateDWRWithChildEntities(dwrIdCreated);
        } else {
          // console.log("DWR updated successfully:", response.data);
          await updateDWRWithChildEntities(currentDwrId);
        }
  
        setIsSubmitClicked(false);
        setIsSubmitting(false);
  
        confirmAlert({
          title: "Success",
          message: "DWR submitted successfully.",
          buttons: [{ label: "OK", onClick: () => {} }],
        });
      } catch (error) {
        console.error("Error processing DWR:", error);
        setIsSubmitClicked(false);
        setIsSubmitting(false);
  
        confirmAlert({
          title: "Error",
          message: "Failed to submit DWR. Please try again.",
          buttons: [{ label: "OK", onClick: () => {} }],
        });
      }
    };
  
    prepareAndSubmitDWR();
  }, [isSubmitClicked]);

  async function updateDWRWithChildEntities(currentDwrId) {
    // console.log(`Starting updateDWRWithChildEntities for DWR ID: ${currentDwrId}`);
    try {
      const employeeTimeRecordsData = rows;
      console.log("Employee Time Records Data:", employeeTimeRecordsData);
  
      // Handle work assignments
      const workAssignmentPromises = workAssignments.map((assignment) => {
        const workAssignmentPayload = {
          description: assignment.description,
          from_time: assignment.fromTime,
          to_time: assignment.toTime,
          dwr: currentDwrId,
        };
        // console.log("Creating work assignment with payload:", workAssignmentPayload);
        return axios.post(
          `${appUrl}/jmrapp/workassignments/`,
          workAssignmentPayload,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      });

    // Handle time record
    try {
      console.log("Creating time record");
      const timeRecordResponse = await axios.post(
        `${appUrl}/jmrapp/timerecord/`,
        { dwr: currentDwrId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const timeRecordId = timeRecordResponse.data.id;
      setTimeRecordId(timeRecordId);
      console.log("Time Record created successfully:", timeRecordId);
      // Create employee time records
      for (const etrData of employeeTimeRecordsData) {
        if (etrData.data.employeeId) {  // Only create records for rows with an employee selected
          try {
            const employeeTimeRecordPayload = {
              employee_id: etrData.data.employeeId,
              time_record_id: timeRecordId,
              start_time: convertTo24Hour(
                etrData.data.startHour,
                etrData.data.startMinute,
                etrData.data.startMeridian
              ),
              end_time: convertTo24Hour(
                etrData.data.stopHour,
                etrData.data.stopMinute,
                etrData.data.stopMeridian
              ),
              rig_time: parseFloat(etrData.data.rig),
              travel_time: parseFloat(etrData.data.travel),
              npt_company_time: parseFloat(etrData.data.nptJmr),
              npt_customer_time: parseFloat(etrData.data.nptCust),
              role: etrData.data.role,
            };
            
            console.log("Creating Employee Time Record with payload:", employeeTimeRecordPayload);
            
            await axios.post(
              `${appUrl}/jmrapp/employeetimerecords/`,
              employeeTimeRecordPayload,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log(`Employee Time Record created for employee ${etrData.data.employeeId}`);
          } catch (error) {
            console.error(`Error creating Employee Time Record for employee ${etrData.data.employeeId}:`, error);
          }
        }
      }
    } catch (error) {
      console.error("Error creating Time Record:", error);
    }
  
      // Fetch existing charge records
      // console.log("Fetching existing charge records for ", currentDwrId);
      const existingChargeRecordsResponse = await axios.get(
        `${appUrl}/jmrapp/chargerecords/?dwr=${currentDwrId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const existingChargeRecords = existingChargeRecordsResponse.data;
      // console.log("Existing charge records:", existingChargeRecords.map(record => ({id: record.id, dwrId: record.dwr, onTurnkey: record.on_turnkey})));

  
      const newChargeRecordIds = {};
  
      // Handle charge records
      for (const type of ["onTurnkey", "offTurnkey"]) {
        // console.log(`Processing ${type} charge record`);
        const chargeItems = type === "onTurnkey" ? onTurnkeyChargeItems : offTurnkeyChargeItems;
        // Check if a charge record already exists
        const existingChargeRecord = await axios.get(
          `${appUrl}/jmrapp/chargerecords/?dwr=${currentDwrId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (existingChargeRecord.data.length > 0) {
          // console.log(`${onTurnkey ? 'On' : 'Off'} turnkey charge record already exists. Updating...`);
          return existingChargeRecord.data[0].id;
        }


        const existingRecord = existingChargeRecords.find(
          (record) => record.on_turnkey === (type === "onTurnkey") && record.dwr === currentDwrId
        );
      
  
        try {
          let chargeRecordId;
  
          if (existingRecord) {
            chargeRecordId = existingRecord.id;
            // console.log(`Updating existing ${type} charge record with ID: ${existingRecord.id}`);
        } else {
            // console.log(`Creating new ${type} charge record for DWR ${currentDwrId}`);
            const chargeRecordResponse = await axios.post(
              `${appUrl}/jmrapp/chargerecords/`,
              {
                dwr: currentDwrId,
                on_turnkey: type === "onTurnkey",
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );
            chargeRecordId = chargeRecordResponse.data.id;
            // console.log(`Created new ${type} charge record with ID: ${chargeRecordId}`);
          }
  
          newChargeRecordIds[type] = chargeRecordId;
  
          if (chargeItems.length > 0) {
            // console.log(`Updating ${type} charge record with items:`, chargeItems);
            const serviceItemCharges = chargeItems
              .filter((item) => item.type === "Service")
              .map(({ id, quantity_used }) => ({
                service_item_id: id,
                quantity_used,
              }));
  
            const inventoryItemCharges = chargeItems
              .filter((item) => item.type === "Inventory")
              .map(({ id, quantity_used }) => ({
                inventory_item_id: id,
                quantity_used,
              }));
  
            // console.log(`Patching ${type} charge record:`, {
            //   service_item_charges: serviceItemCharges,
            //   inventory_item_charges: inventoryItemCharges,
            // });
  
            await axios.patch(
              `${appUrl}/jmrapp/chargerecords/${chargeRecordId}/`,
              {
                service_item_charges: serviceItemCharges,
                inventory_item_charges: inventoryItemCharges,
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );
  
            // console.log(`${type} charge record updated successfully`);
          }
        } catch (error) {
          console.error(
            `Error processing ${type} charge record:`,
            error.response ? error.response.data : error.message
          );
        }
      }
  
      // Handle time record
      // try {
      //   console.log("Creating time record");
      //   const timeRecordResponse = await axios.post(
      //     `${appUrl}/jmrapp/timerecord/`,
      //     { dwr: currentDwrId },
      //     { headers: { Authorization: `Bearer ${token}` } }
      //   );
      //   setTimeRecordId(timeRecordResponse.data.id);
      //   console.log("Time Record created successfully:", timeRecordResponse.data.id);
      // } catch (error) {
      //   console.error("Error creating Time Record:", error);
      // }
  
      await Promise.all(workAssignmentPromises);
  
      setChargeRecordIds(newChargeRecordIds);
      // console.log("All child entities updated successfully");
      // console.log("New charge record IDs:", newChargeRecordIds);
    } catch (error) {
      console.error("Error updating child entities:", error);
    }
  }
  

  useEffect(() => {
    // console.log("Charge record IDs changed:", chargeRecordIds);
    if (!chargeRecordIds.onTurnkey && !chargeRecordIds.offTurnkey) {
      // console.log("No charge record IDs available, skipping update");
      return;
    }
  
    const updateChargeRecord = async (type) => {
      // console.log(`Starting update for ${type} charge record`);
      const chargeItems =
        type === "onTurnkey" ? onTurnkeyChargeItems : offTurnkeyChargeItems;
      const initialItems =
        type === "onTurnkey" ? initialOnTurnkeyItems : initialOffTurnkeyItems;
  
      // console.log(`Current ${type} items:`, chargeItems);
      // console.log(`Initial ${type} items:`, initialItems);
  
      const hasChanges =
        JSON.stringify(chargeItems) !== JSON.stringify(initialItems);
  
      if (!hasChanges) {
        // console.log(`No changes detected for ${type} charge records. Skipping update.`);
        return;
      }
  
      // console.log(`Changes detected for ${type} charge records. Proceeding with update.`);
  
      const serviceItemCharges = chargeItems
        .filter((item) => item.type === "Service")
        .map(({ id, quantity_used }) => ({
          service_item_id: id,
          quantity_used,
        }));
  
      const inventoryItemCharges = chargeItems
        .filter((item) => item.type === "Inventory")
        .map(({ id, quantity_used }) => ({
          inventory_item_id: id,
          quantity_used,
        }));
      
      const miscellaneousItemCharges = chargeItems
        .filter((item) => item.type === "Miscellaneous")
        .map(({ id, quantity_used, name, price }) => ({
          miscellaneous_item_id: id,
          quantity_used,
          custom_name: name,
          price_at_use: parseFloat(price),
        }));
  
      const chargeRecordId = chargeRecordIds[type];
      // console.log(`Updating ${type} charge record with ID:`, chargeRecordId);
      // console.log("Service item charges:", serviceItemCharges);
      // console.log("Inventory item charges:", inventoryItemCharges);
      // console.log("Miscellaneous item charges:", miscellaneousItemCharges);

      try {
        const response = await axios.patch(
          `${appUrl}/jmrapp/chargerecords/${chargeRecordId}/`,
          {
            service_item_charges: serviceItemCharges,
            inventory_item_charges: inventoryItemCharges,
            miscellaneous_item_charges: miscellaneousItemCharges,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        // console.log(`${type} ChargeRecord updated successfully:`, response.data);
      } catch (error) {
        console.error(
          `Error updating ${type} charge record with items:`,
          error.response ? error.response.data : error.message
        );
      }
    };
  
    if (chargeRecordIds.onTurnkey) {
      // console.log("Updating onTurnkey charge record");
      updateChargeRecord("onTurnkey");
    }
    if (chargeRecordIds.offTurnkey) {
      // console.log("Updating offTurnkey charge record");
      updateChargeRecord("offTurnkey");
    }
  }, [
    chargeRecordIds,
    onTurnkeyChargeItems,
    offTurnkeyChargeItems,
    initialOnTurnkeyItems,
    initialOffTurnkeyItems,
    token,
  ]);

  useEffect(() => {
    if (chargeRecordIds.onTurnkey) {
      handleUpdateChargeRecords('onTurnkey');
    }
    if (chargeRecordIds.offTurnkey) {
      handleUpdateChargeRecords('offTurnkey');
    }
  }, [chargeRecordIds]);


  const validRows = useMemo(
    () =>
      rows.filter((row) => {
        const employeeId = row.data.employeeId;
        return employeeId && String(employeeId).trim() !== "";
      }),
    [rows]
  );

  useEffect(() => {
    async function submitEmployeeTimeRecords() {
      if (!timeRecordUpdateNeeded || !timeRecordId || validRows.length === 0)
        return;

      for (const row of validRows) {
        const payload = {
          time_record_id: timeRecordId,
          employee_id: row.data.employeeId,
          start_time: convertTo24Hour(
            row.data.startHour,
            row.data.startMinute,
            row.data.startMeridian
          ),
          end_time: convertTo24Hour(
            row.data.stopHour,
            row.data.stopMinute,
            row.data.stopMeridian
          ),
          rig_time: row.data.rig || 0,
          travel_time: row.data.travel || 0,
          npt_company_time: row.data.nptJmr || 0,
          npt_customer_time: row.data.nptCust || 0,
          role: row.data.role,
        };
        // console.log("ETR PAYLOAD: ", payload);

        let url = appUrl + `/jmrapp/employeetimerecords/`;
        let method = "post";
        if (row.data.etrId) {
          url += `${row.data.etrId}/`;
          method = "patch";
        }

        try {
          const response = await axios({
            method,
            url,
            data: payload,
            headers: { Authorization: `Bearer ${token}` },
          });

          if (!row.data.etrId) {
            const newEtrId = response.data.id;
            row.data.etrId = newEtrId;
          }

          // console.log(
          //   `EmployeeTimeRecord ${row.id ? "updated" : "created"} successfully.`
          // );
        } catch (error) {
          console.error(
            `Error in EmployeeTimeRecord ${row.id ? "update" : "creation"}:`,
            error
          );
        }
      }

      setTimeRecordUpdateNeeded(false);
    }

    submitEmployeeTimeRecords();
  }, [timeRecordUpdateNeeded, timeRecordId, validRows, token]);

  useEffect(() => {
    const fetchWorkDescriptions = async () => {
      setIsLoadingWorkDescriptions(true);
      try {
        const token = localStorage.getItem("my-jwt");
        const response = await axios.get(
          appUrl + `/jmrapp/workdescriptions/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setWorkDescriptions(response.data || []);
      } catch (error) {
        console.error("There was an error fetching work descriptions:", error);
      } finally {
        setIsLoadingWorkDescriptions(false);
      }
    };
    fetchWorkDescriptions();
  }, []);

  useEffect(() => {
    const sendChargeRecordsUpdate = async () => {
      if (!selectedDwr) {
        console.error("No DWR selected.");
        return;
      }

      const chargeItems =
        updateChargeRecordsTriggered.type === "onTurnkey"
          ? onTurnkeyChargeItems
          : offTurnkeyChargeItems;
      const chargeRecordId = chargeRecordIds[updateChargeRecordsTriggered.type];
      let method = "patch";
      let url = `${appUrl}/jmrapp/chargerecords/${chargeRecordId}/`;

      if (!chargeRecordId) {
        method = "post";
        url = `${appUrl}/jmrapp/chargerecords/`;
      }

      const chargeRecordsPayload = {
        dwr: selectedDwr.id,
        service_item_charges: chargeItems
          .filter((item) => item.type === "Service")
          .map((item) => ({
            id: item.chargeId,
            quantity_used: parseFloat(item.quantity_used),
            service_item_id: item.id,
          })),
        inventory_item_charges: chargeItems
          .filter((item) => item.type === "Inventory")
          .map((item) => ({
            id: item.chargeId,
            quantity_used: parseFloat(item.quantity_used),
            inventory_item_id: item.id,
          })),
      };

      try {
        const response = await axios({
          method,
          url,
          data: chargeRecordsPayload,
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!chargeRecordId) {
            setChargeRecordIds((prevIds) => ({
              ...prevIds,
              [updateChargeRecordsTriggered.type]: response.data.id,
            }));
          }
    

        // console.log(
        //   `${updateChargeRecordsTriggered.type} charge records updated successfully.`
        // );
      } catch (error) {
        console.error(
          `Failed to update ${updateChargeRecordsTriggered.type} charge records:`,
          error
        );
      }
    };

    if (updateChargeRecordsTriggered.triggered) {
      sendChargeRecordsUpdate();
      setUpdateChargeRecordsTriggered({ triggered: false, type: null });
    }
  }, [
    updateChargeRecordsTriggered,
    selectedDwr,
    onTurnkeyChargeItems,
    offTurnkeyChargeItems,
    token,
  ]);

  useEffect(() => {
    const fetchRigData = async () => {
      try {
        const token = localStorage.getItem("my-jwt");
        const rigId = well.assigned_rig.id;
        if (rigId) {
          const response = await axios.get(
            appUrl + `/jmrapp/rigs/${rigId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log('RIG DATA: ', response.data)
          setRigData(response.data);
        }
      } catch (error) {
        console.error(
          "There was an error fetching rig data:",
          error.response ? error.response.data : error.message
        );
      }
    };

    // if (formData.wellData?.assigned_rig) {
    fetchRigData();
    // }
  }, []);

  useEffect(() => {
    const fetchInventoryItems = async () => {
      try {
        const token = localStorage.getItem("my-jwt");
        const response = await axios.get(
          appUrl + `/jmrapp/active-inventory-items`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setInventoryItems(response.data || []);
      } catch (error) {
        console.error("Failed to fetch inventory items:", error);
      }
    };

    fetchInventoryItems();
  }, []);

  useEffect(() => {
    const fetchMiscellaneousItems = async () => {
      try {
        const response = await axios.get(
          `${appUrl}/jmrapp/active-miscellaneous-items`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setMiscellaneousItems(response.data || []);
      } catch (error) {
        console.error("Failed to fetch miscellaneous items:", error);
      }
      // console.log('MISC ITEMS: ', miscellaneousItems)
    };
  
    fetchMiscellaneousItems();
  }, [token,]);

  // New function to fetch the current pricebook for the well
  const fetchCurrentPricebook = useCallback(async () => {
    if (!well || !well.id) return;

    setIsLoadingPricebook(true);
    try {
      const response = await axios.get(`${appUrl}/jmrapp/wells/${well.id}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCurrentPricebook(response.data.inventory_pricebook);
    } catch (error) {
      console.error("Failed to fetch current pricebook:", error);
    } finally {
      setIsLoadingPricebook(false);
    }
  }, [well, token]);

  useEffect(() => {
    fetchCurrentPricebook();
  }, [fetchCurrentPricebook]);


  useEffect(() => {
    const fetchServiceItemsForCustomer = async () => {
      const customer_id = well.customer?.id;
      if (!customer_id) {
        // console.log(
        //   "Customer ID is not available. Skipping fetch for service items."
        // );
        return;
      }

      try {
        const token = localStorage.getItem("my-jwt");
        const response = await axios.get(
          appUrl + `/jmrapp/pricebooks/?customer_id=${customer_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setServiceItems(response.data || []);
      } catch (error) {
        console.error("Failed to fetch service items for customer:", error);
      }
    };

    if (well?.customer.id) {
      fetchServiceItemsForCustomer();
    }
  }, [well?.customer.id]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token = localStorage.getItem("my-jwt");
        const response = await axios.get(appUrl + `/jmrapp/employee`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEmployees(response.data);
      } catch (error) {
        console.error("Failed to fetch employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    const updatedInventoryObjects = selectedInventoryItems.map((id) => {
      const item = inventoryItems.find((item) => item.id === id);
      return {
        ...item,
        quantity_used: 0,
        original_quantity_available: item.quantity_available,
      };
    });

    setSelectedInventoryObjects(updatedInventoryObjects);

    const updatedServiceObjects = selectedServiceItems
      .map((id) => serviceItems.find((item) => item.id === id))
      .filter((item) => item != null)
      .map((item) => ({ ...item, quantity_used: item.quantity_used || 1 }));

    setSelectedServiceObjects(updatedServiceObjects);
  }, [
    selectedInventoryItems,
    selectedServiceItems,
    inventoryItems,
    serviceItems,
  ]);

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const shortDate = selectedDate.replaceAll("-", "").slice(2);
    const rigName = rigData ? rigData.name : "";
    const ticketNumber = `${shortDate}${well.assigned_rig}`;
    setFormData((prevState) => ({
      ...prevState,
      date: selectedDate,
      // ticketNumber,
    }));
  };


  const handleDayChange = (e) => {
    const inputDay = e.target.value;
    setDay(inputDay);
    setFormData((prevState) => ({
      ...prevState,
      day: inputDay,
    }));
  };

  const handleLastDayChange = (value) => {
    setIsLastDay(value);
    setFormData((prevState) => ({
      ...prevState,
      isLastDay: value,
    }));
  };

  const handleContactChange = (e) => {
    const contactId = e.target.value;
    // console.log("Selected Contact ID:", contactId);
    setFormData((prevState) => ({
      ...prevState,
      contact_id: contactId ? parseInt(contactId) : null,
    }));
  };

  const handleDWRUpdate = async () => {
    if (!selectedDwr) return;

    try {
      const currentFormData = formData;
      const currentNotes = notes;
      const currentDay = day;
      const currentLastDay = isLastDay;
      // const currentTicketNumber = formData.ticketNumber;


      const payload = {
        date: currentFormData.date,
        day: currentDay,
        is_last_day: currentLastDay,
        notes: currentNotes,
        is_locked: isLocked,
        is_approved: isApproved,
        // ticket_number: currentTicketNumber,  // Include the ticket_number in the payload
      };

      if (currentFormData.contact_id !== null) {
        payload.contact_id = currentFormData.contact_id;
      }

      // console.log("Payload for PATCH request:", payload);

      const response = await axios.patch(
        `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // console.log("Server response:", response.data);

      setSelectedDwr(response.data);
      setFormData((prevState) => ({
        ...prevState,
        contact_id: response.data.contact ? response.data.contact.id : null,
        day: response.data.day,
        ticketNumber: response.data.ticket_number,  // Update the ticketNumber in the form data
        isLastDay: response.data.is_last_day,
        isLocked: response.data.is_locked,
        isApproved: response.data.is_approved,
      }));
      setDay(response.data.day);
      setNotesUpdated(true);
      setIsLastDay(response.data.is_last_day);
      setIsLocked(response.data.isLocked);
      setIsApproved(response.data.isApproved);

      confirmAlert({
        title: "Success",
        message: "DWR details updated successfully.",
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error(
        "Failed to update DWR details:",
        error.response ? error.response.data : error
      );
      confirmAlert({
        title: "Error",
        message: `Failed to update DWR details: ${
          error.response ? JSON.stringify(error.response.data) : error.message
        }`,
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleOpenChargeModal = (type) => {
    setShowDuplicateChargeError(false);
    setIsChargeModalOpen((prev) => ({ ...prev, [type]: true }));
  };
  const handleCloseChargeModal = (type) =>
    setIsChargeModalOpen((prev) => ({ ...prev, [type]: false }));



  const handleChargeRecordSelect = async (
    selectedInventoryIds,
    selectedServiceIds,
    selectedMiscellaneousIds,
    type
  ) => {
    const fetchItemDetails = async (id, itemType) => {
      const response = await axios.get(
        `${appUrl}/jmrapp/${itemType}/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    };
  
    const getPriceFromPricebook = (item, itemType) => {
      if (!currentPricebook || isLoadingPricebook) return item.price;
  
      const pricebookItem = currentPricebook.find(
        pbi => (itemType === 'Inventory' ? pbi.inventory_item : pbi.service_item) === item.id
      );
  
      return pricebookItem ? pricebookItem.price : item.price;
    };
  
    try {
      const newInventoryItemsDetails = await Promise.all(
        selectedInventoryIds.map((id) => fetchItemDetails(id, "inventoryitems"))
      );
      const newServiceItemsDetails = await Promise.all(
        selectedServiceIds.map((id) => fetchItemDetails(id, "serviceitems"))
      );
      const newMiscellaneousItemsDetails = await Promise.all(
        selectedMiscellaneousIds.map((id) => fetchItemDetails(id, "miscellaneous-item"))
      );
    
  
      let newChargeItems = [
        ...newInventoryItemsDetails.map((item) => ({
          ...item,
          id: item.id,
          type: "Inventory",
          quantity_used: 1,
          price: getPriceFromPricebook(item, "Inventory"),
          quantity_available: item.quantity_available,
          netsuite_id: item.ns_eid,
          unit_type: item.unit_type,
          name: item.name,
        })),
        ...newServiceItemsDetails.map((item) => ({
          ...item,
          id: item.id,
          type: "Service",
          quantity_used: 1,
          price: getPriceFromPricebook(item, "Service"),
          quantity_available: item.quantity_available,
          netsuite_id: item.endpoint_id,
          unit_type: item.unit_type,
          name: item.name,
        })),
        ...newMiscellaneousItemsDetails.map((item) => ({
          ...item,
          id: item.id,
          type: "Miscellaneous",
          quantity_used: 1,
          price: item.price,
          customName: item.name,
          customPrice: item.price,
          endpoint_id: item.endpoint_id,
        })),
      ];
  
      const existingChargeItems =
        type === "onTurnkey" ? onTurnkeyChargeItems : offTurnkeyChargeItems;
  
      newChargeItems = newChargeItems.filter(
        (newItem) =>
          !existingChargeItems.some(
            (existingItem) =>
              existingItem.id === newItem.id &&
              existingItem.type === newItem.type
          )
      );
  
      if (type === "onTurnkey") {
        setOnTurnkeyChargeItems((prevItems) => [
          ...prevItems,
          ...newChargeItems,
        ]);
      } else {
        setOffTurnkeyChargeItems((prevItems) => [
          ...prevItems,
          ...newChargeItems,
        ]);
      }
  
      setIsChargeModalOpen((prev) => ({ ...prev, [type]: false }));
    } catch (error) {
      console.error("Error fetching item details:", error);
      confirmAlert({
        title: "Error",
        message: "Failed to add charge items. Please try again.",
        buttons: [{ label: "OK", onClick: () => {} }],
      });
    }
  };

  const handleSelectWorkDescriptions = (selectedDescriptions) => {
    // Filter out descriptions already in workAssignments
    const uniqueDescriptions = selectedDescriptions.filter(
      (desc) =>
        !workAssignments.some(
          (assignment) => assignment.workDescriptionId === desc.id
        )
    );

    // Convert to work assignments format and append to existing assignments
    const newAssignments = uniqueDescriptions.map((desc) => ({
      workDescriptionId: desc.id,
      workDescriptionName: desc.description,
      fromTime: "", // Initialize with default values
      toTime: "", // Initialize with default values
    }));

    setWorkAssignments((prevAssignments) => [
      ...prevAssignments,
      ...newAssignments,
    ]);
    handleCloseModal();
  };

  const handleSubmitClick = () => {
    if (!selectedDwr && !formData.date) {
      confirmAlert({
        title: "Error",
        message: "Please enter a date before submitting the DWR.",
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
      return;
    }
  
    setIsSubmitting(true);
    setIsSubmitClicked(true);
    setTimeRecordUpdateNeeded(true);
  };

  const handleDeleteAssignmentFromDWRForm = async (assignmentId) => {
    try {
      await axios.delete(
        appUrl + `/jmrapp/workassignments/${assignmentId}/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setWorkAssignments((currentAssignments) =>
        currentAssignments.filter(
          (assignment) => assignment.id !== assignmentId
        )
      );
    } catch (error) {
      console.error(`Failed to delete work assignment ${assignmentId}:`, error);
    }
  };

  const handleUpdateWorkAssignments = async () => {
    const updates = workAssignments
      .filter((assignment) => assignment.id)
      .map(async (assignment) => {
        const { id, description, fromTime, toTime } = assignment;
        const payload = {
          description,
          from_time: fromTime,
          to_time: toTime,
        };
        return axios.patch(
          appUrl + `/jmrapp/workassignments/${id}/`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      });

    const creations = workAssignments
      .filter((assignment) => !assignment.id)
      .map(async (assignment) => {
        const { description, fromTime, toTime } = assignment;
        const payload = {
          description,
          from_time: fromTime,
          to_time: toTime,
          dwr: selectedDwr.id,
        };
        // console.log("CREATION PAYLOAD: ", payload);

        return axios.post(appUrl + `/jmrapp/workassignments/`, payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
      });

    try {
      const responses = await Promise.all([...updates, ...creations]);
      const createdAssignments = responses
        .slice(updates.length)
        .map((response) => response.data);

      setWorkAssignments((prevAssignments) => [
        ...prevAssignments.filter((assignment) => assignment.id),
        ...createdAssignments,
      ]);

      // console.log("Work assignments processed successfully.");

      confirmAlert({
        title: "Success",
        message: "Work assignments updated successfully.",
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error(
        "An error occurred while processing work assignments:",
        error
      );
    }
  };



const handleUpdateChargeRecords = useCallback(async () => {
    if (!selectedDwr) {
      console.error("No DWR selected. Cannot update charge records.");
      return;
    }
  
    // console.log(`Updating charge records for DWR ${selectedDwr.id}`);
    // console.log("Current chargeRecordIds:", chargeRecordIds);
  
    const updateChargeRecord = async (items, type) => {
      // console.log('MISC ID: ', items)
      const chargeRecordId = chargeRecordIds[type];
      if (!chargeRecordId) {
        console.error(`No charge record ID found for ${type}`);
        return;
      }
  
      // console.log(`Updating ${type} charge record with ID: ${chargeRecordId}`);
  
      const initialItems = type === "onTurnkey" ? initialOnTurnkeyItems : initialOffTurnkeyItems;
  
      // Check if there are any changes
      const hasChanges = JSON.stringify(items) !== JSON.stringify(initialItems);
  
      if (hasChanges) {
        const payload = {
          service_item_charges: items
            .filter((item) => item.type === "Service")
            .map((item) => ({
              id: item.chargeId,
              service_item_id: item.id,
              quantity_used: item.quantity_used,
            })),
          inventory_item_charges: items
            .filter((item) => item.type === "Inventory")
            .map((item) => ({
              id: item.chargeId,
              inventory_item_id: item.id,
              quantity_used: item.quantity_used,
            })),
            miscellaneous_item_charges: items
            .filter((item) => item.type === "Miscellaneous")
            .map((item) => ({
              id: item.chargeId,
              miscellaneous_item_id: item.id,
              quantity_used: item.quantity_used,
              custom_name: item.name || item.custom_name,
              price_at_use: item.price || item.price_at_use,
            })),
        };
  
        try {
          const response = await axios.patch(
            `${appUrl}/jmrapp/chargerecords/${chargeRecordId}/`,
            payload,
            { headers: { Authorization: `Bearer ${token}` } }
          );
  
          // console.log(`${type} charge records updated successfully:`, response.data);
  
          // Update the initial state after successful update
          if (type === "onTurnkey") {
            setInitialOnTurnkeyItems(items);
          } else {
            setInitialOffTurnkeyItems(items);
          }
  
          // Verify that the updated charge record belongs to the current DWR
          if (response.data.dwr !== selectedDwr.id) {
            console.error(`Updated charge record ${chargeRecordId} does not belong to the current DWR ${selectedDwr.id}`);
          }
        } catch (error) {
          console.error(`Error updating ${type} charge records:`, error.response ? error.response.data : error.message);
        }
      } else {
        // console.log(`No changes detected for ${type} charge records. Skipping update.`);
      }
    };
  
    // Update both charge records
    await updateChargeRecord(onTurnkeyChargeItems, "onTurnkey");
    await updateChargeRecord(offTurnkeyChargeItems, "offTurnkey");
  
  }, [selectedDwr, chargeRecordIds, onTurnkeyChargeItems, offTurnkeyChargeItems, initialOnTurnkeyItems, initialOffTurnkeyItems, token]);
  
  useEffect(() => {
    if (updateChargeRecordsTriggered.triggered) {
      handleUpdateChargeRecords();
      setUpdateChargeRecordsTriggered({ triggered: false, type: null });
    }
  }, [updateChargeRecordsTriggered, handleUpdateChargeRecords]);
  
  const onUpdateChargeRecordsTrigger = useCallback((type) => {
    setUpdateChargeRecordsTriggered({ triggered: true, type });
  }, []);

  const handleNotesUpdate = async () => {
    if (!selectedDwr) return;

    try {
      await axios.patch(
        appUrl + `/jmrapp/dailyworkrecords/${selectedDwr.id}/`,
        { notes: notes },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotesUpdated(true);

      confirmAlert({
        title: "Success",
        message: "Notes updated successfully.",
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error("Failed to update notes:", error);
    }
  };

  const handleUnlock = async () => {
    try {
      const response = await axios.post(
        `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/unlock/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsLocked(false);
      // Update the selectedDwr state with the new unlocked status
      setSelectedDwr({ ...selectedDwr, is_locked: false });
            // Send notification email after unlocking
      // await axios.post(
      //   `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/send_unlock_notification/`,
      //   {},
      //   { headers: { Authorization: `Bearer ${token}` } }
      // );
    } catch (error) {
      console.error('Failed to unlock DWR:', error);
    }
  };

  const handleManualLock = async () => {
    try {
      const response = await axios.post(
        `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/manual_lock/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsLocked(true);
      // Update the selectedDwr state with the new locked status
      setSelectedDwr({ ...selectedDwr, is_locked: true });
    } catch (error) {
      console.error('Failed to lock DWR:', error);
    }
  };

  const handleLockChange = async (event) => {
    const newLockedState = event.target.checked
    if (selectedDwr.well.status === 'Closed' && isLocked) {
      confirmAlert({
        title: "Well Closed",
        message: "This well has been locked. To unlock and modify this DWR, you must contact an Admin to reopen the well.",
        buttons: [{ label: "OK" }],
      });
      return;
    }
    try {
      if (newLockedState) {
        await handleManualLock();
      } else {
        await handleUnlock();
      }
      setIsLocked(newLockedState);
    } catch (error) {
      console.error('Failed to change lock state:', error);
      // Optionally show an error message to the user
    }
  };

    const handleApprovalChange = async (event) => {
    if (isLocked) {
      confirmAlert({
        title: "DWR Locked",
        message: "Cannot modify approval status of a locked DWR.",
        buttons: [{ label: "OK" }],
      });
      return;
    }
  
    const newApprovalState = event.target.checked;
    try {
      const response = await axios.patch(
        `${appUrl}/jmrapp/dailyworkrecords/${selectedDwr.id}/`,
        { is_approved: newApprovalState },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsApproved(newApprovalState);
      // Update the selectedDwr state with the new approved status
      setSelectedDwr({ ...selectedDwr, is_approved: newApprovalState });
  
      confirmAlert({
        title: "Success",
        message: `DWR ${newApprovalState ? 'approved' : 'unapproved'} successfully.`,
        buttons: [{ label: "OK" }],
      });
    } catch (error) {
      console.error('Failed to change approval state:', error);
      confirmAlert({
        title: "Error",
        message: "Failed to change approval status. Only district managers can approve DWRs.",
        buttons: [{ label: "OK" }],
      });
    }
  };

  const handleNavigate = async (direction) => {
    setIsNavigating(true);
    const adjacentDWR = getAdjacentDWR(selectedDwr, direction);
    if (adjacentDWR) {
      try {
        const fullDwrData = await fetchFullDWRData(adjacentDWR.id);
        console.log("FULL DWR DATA: ",fullDwrData)
        setSelectedDwr(fullDwrData);
        // Reset form state for the new DWR
        setFormData({
          id: fullDwrData.id,
          date: fullDwrData.date,
          day: fullDwrData.day,
          isLastDay: fullDwrData.is_last_day,
          ticketNumber: fullDwrData.ticket_number,
          is_locked: fullDwrData.is_locked,
          is_approved: fullDwrData.is_approved,
          notes: fullDwrData.notes || "",
          contact_id: fullDwrData.contact ? fullDwrData.contact.id : null,
          // ... (update other necessary fields)
        });
        setDay(fullDwrData.day);
        setNotes(fullDwrData.notes || "");
        setIsLastDay(fullDwrData.is_last_day);
        setIsLocked(fullDwrData.is_locked);
        setIsApproved(fullDwrData.is_approved);
        // ... (update other necessary state)
        // Update time records
        if (fullDwrData.time_record) {
          setTimeRecordId(fullDwrData.time_record.id);
          setEmployeeTimeRecords(fullDwrData.time_record.employee_time_records || []);
        } else {
          setTimeRecordId(null);
          setEmployeeTimeRecords([]);
        }
        
      } catch (error) {
        console.error('Failed to fetch full DWR data:', error);
        confirmAlert({
          title: "Error",
          message: "Failed to load DWR data. Please try again.",
          buttons: [{ label: "OK" }],
        });
      } finally {
        setIsNavigating(false);
      }
    } else {
      setIsNavigating(false);
    }
  };


  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
      {selectedDwr ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <Button
            startIcon={<ArrowBackIosNewIcon />}
            onClick={() => handleNavigate('prev')}
            disabled={isNavigating || !getAdjacentDWR(selectedDwr, 'prev')}
          >
            Previous
          </Button>
          <Typography variant="h6">
            {formData.date 
              ? `${new Date(formData.date).toLocaleDateString("en-US", { timeZone: "UTC" })} - ${formData.day}`
              : 'No Date'}
          </Typography>
          <Button
            endIcon={<ArrowForwardIosIcon />}
            onClick={() => handleNavigate('next')}
            disabled={isNavigating || !getAdjacentDWR(selectedDwr, 'next')}
          >
            Next
          </Button>
        </Box>
      ) : (
        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
          New DWR
        </Typography>
      )}
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Date"
              type="date"
              value={formData.date}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={isLocked}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              label="Day"
              type="text"
              value={day}
              onChange={handleDayChange}
              placeholder="Enter day"
              fullWidth
              disabled={isLocked}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="body1">
              Ticket Number: {formData.ticketNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FormControl fullWidth disabled={isLocked}>
              <InputLabel id="contact-label">Contact</InputLabel>
              <Select
                labelId="contact-label"
                id="contact"
                value={formData.contact_id || ""}
                onChange={handleContactChange}
                label="Contact"
              >
                <MenuItem value="">
                  <em>Select a contact</em>
                </MenuItem>
                {contacts.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.first_name} {contact.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch 
                  checked={isLastDay}
                  onChange={(e) => handleLastDayChange(e.target.checked)}
                    color="primary"
                    disabled={isLocked}
                  />
                }
                label="Is Last Day"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={isLocked}
                  onChange={handleLockChange}
                  icon={<LockOpenIcon />}
                  checkedIcon={<LockIcon />}
                  disabled={!selectedDwr || !currentUser || (!hasPermission('jmrapp.can_lock_dwr') && !hasPermission('jmrapp.can_unlock_dwr'))}
                />
              }
              label={isLocked ? "Locked" : "Unlocked"}
            />
          </Grid>
                    <Grid item xs={12} sm={6} md={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={isApproved}
                  onChange={handleApprovalChange}
                  icon={<ThumbUpOffAltIcon />}
                  checkedIcon={<ThumbUpIcon />}
                  disabled={!selectedDwr ||
                     !currentUser 
                     || (!currentUser.is_district_manager && !currentUser.is_superuser)
                        || isLocked
                      }
                />
              }
              label={isApproved ? "Approved" : "Not Approved"}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        <WellForm well={well} disabled={true} />
      </Box>
      <div>
        <h3>Work Assignments</h3>
        {isLoadingWorkDescriptions ? (
          <p>Loading work descriptions...</p>
        ) : (
          <WorkAssignment
            workAssignments={workAssignments}
            setWorkAssignments={setWorkAssignments}
            workDescriptions={workDescriptions}
            onDeleteAssignment={handleDeleteAssignmentFromDWRForm}
            disabled={isLocked}
          />
        )}
      </div>
      <div>
        {isModalOpen && (
          <WorkDescriptionsModal
            workDescriptions={workDescriptions}
            setSelectedWorkDescriptions={handleSelectWorkDescriptions}
            handleClose={handleCloseModal}
            disabled={isLocked}
          />
        )}
        {selectedDwr && (
          <button className="btn hover" onClick={handleUpdateWorkAssignments} disabled={isLocked}>
            Update Work Assignments
          </button>
        )}
      </div>
      <h3>Charge Record</h3>
      <button
        className="btn hover"
        onClick={() => handleOpenChargeModal("onTurnkey")}
        disabled={isDwrLoading || areChargesLoading || isLocked}
      >
        {isDwrLoading || areChargesLoading
          ? "Loading..."
          : "Choose On-Turnkey Charges"}
      </button>
      <button
        className="btn hover"
        onClick={() => handleOpenChargeModal("offTurnkey")}
        disabled={isDwrLoading || areChargesLoading || isLocked}
      >
        {isDwrLoading || areChargesLoading
          ? "Loading..."
          : "Choose Off-Turnkey Charges"}
      </button>
      {isChargeModalOpen.onTurnkey && !isDwrLoading && !areChargesLoading && (
        <ChargeRecordModal
          open={isChargeModalOpen.onTurnkey}
          handleClose={() => handleCloseChargeModal("onTurnkey")}
          inventoryItems={inventoryItems}
          serviceItems={serviceItems}
          miscellaneousItems={miscellaneousItems}
          onChargeRecordSelect={(selectedInventoryIds, selectedServiceIds, selectedMiscellaneousIds) =>
            handleChargeRecordSelect(
              selectedInventoryIds,
              selectedServiceIds,
              selectedMiscellaneousIds,
              "onTurnkey"
            )
          }
          type="onTurnkey"
          disabled={isLocked}
        />
      )}
      {isChargeModalOpen.offTurnkey && !isDwrLoading && !areChargesLoading && (
        <ChargeRecordModal
          open={isChargeModalOpen.offTurnkey}
          handleClose={() => handleCloseChargeModal("offTurnkey")}
          inventoryItems={inventoryItems}
          serviceItems={serviceItems}
          miscellaneousItems={miscellaneousItems}
          onChargeRecordSelect={(selectedInventoryIds, selectedServiceIds, selectedMiscellaneousIds) =>
            handleChargeRecordSelect(
              selectedInventoryIds,
              selectedServiceIds,
              selectedMiscellaneousIds,
              "offTurnkey"
            )
          }
          type="offTurnkey"
          disabled={isLocked}
        />
      )}
      <ChargeRecord
        inventoryItems={selectedInventoryObjects}
        serviceItems={selectedServiceObjects}
        onTurnkeyChargeItems={onTurnkeyChargeItems}
        offTurnkeyChargeItems={offTurnkeyChargeItems}
        setOnTurnkeyChargeItems={setOnTurnkeyChargeItems}
        setOffTurnkeyChargeItems={setOffTurnkeyChargeItems}
        quantities={quantities}
        updateQuantities={updateQuantities}
        showDuplicateError={showDuplicateChargeError}
        onUpdateChargeRecords={onUpdateChargeRecordsTrigger}
        selectedDwr={selectedDwr}
        disableUpdate={!selectedDwr || isDwrLoading || areChargesLoading || isLocked}
        well={well}
        setZeroAllPrices={setZeroAllPrices}
        chargeRecordIds={chargeRecordIds}
        currentPricebook={currentPricebook}
        isLoadingPricebook={isLoadingPricebook}
        disabled={isLocked}
      />
      <h3>Time Record</h3>
      <TimeRecord
        ref={timeRecordRef}
        employees={employees}
        existingETRs={memoizedEmployeeTimeRecords}
        selectedDwr={selectedDwr}
        disabled={isLocked}
      />
      <h3>Notes</h3>
      <textarea
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        maxLength={2000}
        rows={4}
        cols={50}
        disabled={isLocked}
      />
      {selectedDwr && (
        <button type="button" onClick={handleDWRUpdate} disabled={notesUpdated || isLocked}>
          Update DWR Details
        </button>
      )}
      {!selectedDwr && (
        <button
          type="button"
          className="submit-dwr-button"
          disabled={isSubmitting || isLocked}
          onClick={handleSubmitClick}
        >
          Submit DWR
        </button>
      )}
      {selectedDwr && currentUser.is_superuser && (
        <button
          type="button"
          className="upload-csv-button"
          onClick={handleUploadCSVClick}
          disabled={isLocked}
        >
          Create Sales Order
        </button>
      )}
    </>
  );

// {/* {selectedDwr && currentUser.is_superuser && (
//     <button
//       type="button"
//       className="create-xlsx-button"
//       onClick={handleCreateXSLXClick}
//     >
//       Create Open Invoice File
//     </button>
//   )} */}




//       {/* Add the button here */}

};

DWRForm.propTypes = {
  selectedDwr: PropTypes.object,
  setSelectedDwr: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DWRForm;
