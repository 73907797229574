import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  Stack,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  Box,
  OutlinedInput,
} from '@mui/material';
import axios from 'axios';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// Get App Url
const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const CreateUserForm = ({ open, onClose, currentUser }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    username: '',
    phone_number: '',
    address: '',
    is_staff: false,
    is_active: true,
    is_manager: false,
    is_district_manager: false,
    rig: '', // single rig assignment
    managed_rigs: [], // multiple rig assignments for managers
    primary_managed_rigs: [], // multiple rig assignments for primary managers
    district_managed_rigs: [], // multiple rig assignments for district managers
  });

  const [availableRigs, setAvailableRigs] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch available rigs based on current user's permissions
  useEffect(() => {
    const fetchRigs = async () => {
      try {
        const response = await axios.get(`${appUrl}/jmrapp/rigs/`);
        setAvailableRigs(response.data);
      } catch (err) {
        setError('Error fetching available rigs');
        console.error('Error:', err);
      }
    };

    if (open) {
      fetchRigs();
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prev => {
      const newData = {
        ...prev,
        [name]: e.target.type === 'checkbox' ? checked : value
      };

      // Clear primary managed rigs if is_manager is turned off
      if (name === 'is_manager' && !checked) {
        newData.primary_managed_rigs = [];
      }

      return newData;
    });
  };

  const handleMultipleRigChange = (event, fieldName) => {
    const {
      target: { value },
    } = event;
    setFormData(prev => ({
      ...prev,
      [fieldName]: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
        const response = await axios.post(`${appUrl}/jmrapp/api/create-user/`, formData);
      setSuccess('User created successfully!');
      // Reset form
      setFormData({
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        username: '',
        phone_number: '',
        address: '',
        is_staff: false,
        is_active: true,
        is_manager: false,
        is_district_manager: false,
        rig: '',
        managed_rigs: [],
        primary_managed_rigs: [],
        district_managed_rigs: [],
      });
      setTimeout(() => {
        onClose();
        setSuccess('');
      }, 2000);
    } catch (err) {
      setError(err.response?.data?.message || 'Error creating user');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New User</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
        
        <Stack spacing={2} sx={{ mt: 2 }}>
          {/* Basic Info Fields */}
          <TextField
            required
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            required
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            required
            label="FirstName.LastName"
            name="username"
            value={formData.username}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            required
            label="First Name"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            required
            label="Last Name"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Phone Number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            multiline
            rows={3}
            fullWidth
          />

          {/* Role Switches */}
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.is_staff}
                  onChange={handleChange}
                  name="is_staff"
                />
              }
              label="Admin Access"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.is_active}
                  onChange={handleChange}
                  name="is_active"
                />
              }
              label="Active"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.is_manager}
                  onChange={handleChange}
                  name="is_manager"
                />
              }
              label="Manager"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.is_district_manager}
                  onChange={handleChange}
                  name="is_district_manager"
                />
              }
              label="District Manager"
            />
          </Stack>

          {/* Rig Assignment Fields */}
          <FormControl fullWidth>
            <InputLabel>Assigned Rig</InputLabel>
            <Select
              value={formData.rig}
              onChange={handleChange}
              name="rig"
              label="Assigned Rig"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {availableRigs.map((rig) => (
                <MenuItem key={rig.id} value={rig.id}>
                  {rig.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Manager Rig Assignments */}
          {formData.is_manager && (
            <>
              <FormControl fullWidth>
                <InputLabel>Managed Rigs</InputLabel>
                <Select
                  multiple
                  value={formData.managed_rigs}
                  onChange={(e) => handleMultipleRigChange(e, 'managed_rigs')}
                  input={<OutlinedInput label="Managed Rigs" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip 
                          key={value} 
                          label={availableRigs.find(rig => rig.id === value)?.name} 
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {availableRigs.map((rig) => (
                    <MenuItem key={rig.id} value={rig.id}>
                      {rig.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>Primary Managed Rigs</InputLabel>
                <Select
                  multiple
                  value={formData.primary_managed_rigs}
                  onChange={(e) => handleMultipleRigChange(e, 'primary_managed_rigs')}
                  input={<OutlinedInput label="Primary Managed Rigs" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip 
                          key={value} 
                          label={availableRigs.find(rig => rig.id === value)?.name} 
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {availableRigs.map((rig) => (
                    <MenuItem key={rig.id} value={rig.id}>
                      {rig.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          {/* District Manager Rig Assignments */}
          {formData.is_district_manager && (
            <FormControl fullWidth>
              <InputLabel>District Managed Rigs</InputLabel>
              <Select
                multiple
                value={formData.district_managed_rigs}
                onChange={(e) => handleMultipleRigChange(e, 'district_managed_rigs')}
                input={<OutlinedInput label="District Managed Rigs" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip 
                        key={value} 
                        label={availableRigs.find(rig => rig.id === value)?.name} 
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {availableRigs.map((rig) => (
                  <MenuItem key={rig.id} value={rig.id}>
                    {rig.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserForm;
